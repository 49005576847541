import ImdfArchiveLoad from '../mapkitJS/IMDFArchive';
import {LevelPicker} from '../mapkitJS/LevelPicker';
import hostName from '../../utilities/hostName';
const secureUrl = new hostName();
secureUrl.initHostName();

export const LOAD_IMDF = ({map, attributes, defaultFloor, token, campusId, ordinalFloor, currentFloorActive, historyTraceStore, showHistoryMap, mapConfigs})=>{

  ImdfArchiveLoad(token, campusId).then(archive=>{
    //console.info(`6.-[MAP] Load IMDF files LOAD_IMDF`);
    const createItemsForOrdinal = (ordinal, callback)=> {
      var mapItems = [];
      // let isResolved = false;
      // According to the IMDF specification, multiple levels may have the same ordinal.
      var levels = archive.levels(ordinal);
      var units = [];
      var openings = [];
      var amenities = [];
      var occupantsWithAnchor = [];
      levels.forEach(function(level) {
        units = units.concat(archive.unitsOnLevel(level.id));
        openings = openings.concat(archive.openingsOnLevel(level.id));
        //amenities = amenities.concat(archive.amenitiesOnLevel(level.id));
        occupantsWithAnchor = occupantsWithAnchor.concat(archive.occupantsWithAnchorsOnLevel(level.id));
      });

      var numberOfItemsToCreate =
      levels.length + units.length + openings.length + amenities.length + occupantsWithAnchor.length;
      var completed = 0;

      function updateCountAndCheckCompleteness() {
        completed += 1;
        if (completed === numberOfItemsToCreate) {
          callback(mapItems);
        }
      };

      handleMap(levels, units, openings, mapItems, updateCountAndCheckCompleteness);
      handleMapAnnotations(occupantsWithAnchor, mapItems, updateCountAndCheckCompleteness);
      return (mapItems);
    };

    const handleMap = (levels, units, openings, mapItems, updateCountAndCheckCompleteness)=>{
      levels.concat(units, openings).forEach(feature => {
        //console.info(`7.-[MAP] load  LEVELS styleForOverlay`)
        window.mapkit.importGeoJSON(feature,
          {
            /* The IMDF archive does not contain style information.
            * Provide the style characteristics for displaying the features, depending on their type
            * and properties.
            */
            styleForOverlay: function(overlay) {
              if (feature.feature_type === "opening") {
                overlay.style.strokeColor = "rgb(255, 255, 255)";
              } else if (feature.feature_type === "unit") {
                overlay.style.fillOpacity = 1;
                overlay.style.strokeColor = "rgb(190, 190, 190)";
                if (feature.properties.category === "nonpublic"
                || feature.properties.category === "opentobelow"
                || feature.properties.category === "structure")
                  overlay.style.fillColor = "rgb(210, 210, 210)";
                else if (feature.properties.category === "walkway"
                || feature.properties.category === "ramp")
                  overlay.style.fillColor = "rgb(255, 255, 255)";
                else if (feature.properties.category === "foodservice"
                || feature.properties.category === "kitchen")
                  overlay.style.fillColor = "rgb(230, 230, 230)";
                else if (feature.properties.category === "elevator"
                || feature.properties.category === "stairs")
                  overlay.style.fillColor = "rgb(208, 220, 228)";
                else if (feature.properties.category === "auditorium"
                || feature.properties.category === "conferenceroom"
                || feature.properties.category === "library"
                || feature.properties.category === "lounge"
                || feature.properties.category === "lobby"
                || feature.properties.category === "waitingroom")
                  overlay.style.fillColor = "rgb(231, 231, 217)";
                else if (/^restroom/.test(feature.properties.category)
                || feature.properties.category === 'mothersroom')
                  overlay.style.fillColor = "rgb(229, 221, 236)";
                else if (feature.properties.category === "room"
                || feature.properties.category === "storage"
                || feature.properties.category === "fitnessroom"
                || feature.properties.category === "laboratory"
                || feature.properties.category === "mailroom"
                || feature.properties.category === "office"
                || feature.properties.category === "phoneroom"
                || feature.properties.category === "serverroom")
                  overlay.style.fillColor = "rgb(238, 238, 238)";
                else {
                  overlay.style.strokeColor = "rgb(230, 230, 230)";
                  overlay.style.fillColor = "rgb(230, 230, 230)";
                }
              } else {
                overlay.style.fillOpacity = 0;
                overlay.style.strokeColor = "rgb(190, 190, 190)";
                overlay.style.lineWidth = 3;
              }
              return overlay.style;
            },
            // itemForPoint:(coordinate, geoJSON)=> {
            //     var name = (feature.properties.name || {}).en || feature.properties.category || feature.id;
            //     var options = {
            //         title: name,
            //         clusteringIdentifier: feature.properties.category,
            //         displayPriority: feature.properties.category === "exhibit" ? 500 : 250,
            //         anchorOffset: new window.DOMPoint(0, -6),
            //         size: { width: 12, height: 12 },
            //         // The `data` field allows custom data to be associated with the annotation.
            //         data: { showTitle: false, category: feature.properties.category }
            //     };
            //     return new window.mapkit.Annotation(coordinate, this.createAnnotationElement, options);
            // },
            geoJSONDidComplete: (itemCollection)=> {
              if (itemCollection) {
                itemCollection.enabled = false;
                itemCollection.selected = false;
                mapItems.push(itemCollection);
                updateCountAndCheckCompleteness();
              }
            },
            geoJSONDidError: (err) =>{
              console.error("[LOADIMDF] An error ocurred in handleMap", err);
              updateCountAndCheckCompleteness();
            }

          }
        );
      });
    };

    const handleMapAnnotations = (occupantsWithAnchor, mapItems, updateCountAndCheckCompleteness)=>{
    /* An `Occupant` feature models a business in a building; it does not have a geometry and
    * therefore has no coordinates.
    * To locate an occupant on the indoor map, use the associated anchor to specify its location.
    */
   //console.info(`8.- [MAP] Loading annotations`);
      occupantsWithAnchor.forEach(function(occupantWithAnchor) {
        var anchor = occupantWithAnchor.anchor,
        occupant = occupantWithAnchor.occupant;

        window.mapkit.importGeoJSON(anchor, {
          itemForPoint: function(coordinate, geoJSON) {
            var name = (occupant.properties.name || {}).en || occupant.properties.category || occupant.id;
            var options = {
              title: occupant.properties.name.en === 'Mezzanine' || occupant.properties.name.en === 'WC4-Surgery' || occupant.properties.category === 'structure' ? '' : name,
              subtitle: "map annotation",
              // size: { width: 12, height: 12 },
              //displayPriority: 250,
              data: { category: occupant.properties.category, showTitle: true },
              selected: false,
              enabled: false,
              visible: false
            };
            var opt;
            if (/^restroom/i.test(occupant.properties.category)) {
              opt = {
                url: {1: secureUrl.hostUrl + '/api/img/ic_restroom.png', 2: secureUrl.hostUrl + '/api/img/ic_restroom.png'},
                size: { width: 8, height: 8 },
                selected: false,
                enabled: false,
                visible: false,
                subtitle: "map annotation",
              };
              return new window.mapkit.ImageAnnotation(coordinate, opt);
            } else if (occupant.properties.category === 'elevator') {
              opt = handleMapImageAnnotations("elevator");
              return new window.mapkit.ImageAnnotation(coordinate, opt);
            } else if (occupant.properties.category === 'stairs') {
              opt = handleMapImageAnnotations("stairs");
              return new window.mapkit.ImageAnnotation(coordinate, opt);
            } else if (occupant.properties.category === 'foodservice') {
              opt = handleMapImageAnnotations("foodservice");
              return new window.mapkit.ImageAnnotation(coordinate, opt);
            } else {
              return new window.mapkit.Annotation(coordinate, createAnnotationElement, options);
            }
          },
          geoJSONDidComplete: function(itemCollection) {
            mapItems.push(itemCollection);
            updateCountAndCheckCompleteness();
          },
          geoJSONDidError: function(err) {
            console.error("[LOADIMDF] An error ocurred in handleMapAnnotations: ", err);
            updateCountAndCheckCompleteness();
          }
        });
      });
    };

    const handleMapImageAnnotations = (type)=>{
      let firstUrl = "";
      let secondUrl = "";

      if (type === "elevator") {
        firstUrl = secureUrl.hostUrl + '/api/img/ic_elevator.png';
        secondUrl = secureUrl.hostUrl + '/api/img/ic_elevator_2x.png';
      } else if (type === "stairs") {
        firstUrl = secureUrl.hostUrl + '/api/img/ic_stairs.png';
        secondUrl = secureUrl.hostUrl + '/api/img/ic_stairs_2x.png';
      } else if (type === "foodservice") {
        firstUrl = secureUrl.hostUrl + '/api/img/ic_food.png';
        secondUrl = secureUrl.hostUrl + '/api/img/ic_food_2x.png';
      }

      var opt = {
        url: {1: firstUrl, 2: secondUrl},
        selected: false,
        enabled: false,
        visible: false,
        subtitle: "map annotation",
      };

      return opt;
    };

    const createAnnotationElement = (coordinate, options)=>{
      var title = document.createElement("div");
      title.className = "circle-annotation-title";
      title.textContent = options.title;
      return title;
    };

    const handleHistoryTracesOnFloorSwitch = (ordinal)=>{
      if (ordinalFloor !== ordinal) {
        if (!currentFloorActive) {
          historyTraceStore.map(jobTrace=>{
            this.map.removeOverlays(jobTrace);
            return null;
          });
          setTimeout(()=>{
            historyTraceStore.map(jobTrace=>{
              this.map.addOverlays(jobTrace);
              return null;
            });
          }, 1000);
        } else {
          historyTraceStore.map(jobTrace=>{
            this.map.removeOverlays(jobTrace);
            return null;
          });
          historyTraceStore.map(jobTrace=>{
            jobTrace.map(overlay=>{
              if (overlay.data.subtitle === "historyTrace" && overlay.data.title !== ordinal) {
                overlay.visible = false;
              } else {
                overlay.visible = true;
              }
              return null;
            });
          });
          setTimeout(()=>{
            historyTraceStore.map(jobTrace=>{
              this.map.addOverlays(jobTrace);
              return null;
            });
          }, 1000);
        }
      }
    };

    const handleShowMapAnnotations = ()=>{
      if (map.region.span.latitudeDelta <= 0.0009819103411885521) {
        map.annotations.map(res=>{
          if (res.subtitle === "map annotation" && !res.visible)
            res.visible = true;
          return null;
        });
      } else {
        map.annotations.map(res=>{
          if (res.subtitle === "map annotation" && res.visible)
            res.visible = false;
          return null;
        });
      }
    };

    var automaticFloorSwitching = true;
    var shownOrdinal = null;
    var expectingOrdinal = null;
    var itemsByOrdinal = {};

    async function showOrdinal(ordinal, userTriggered) {
      if (ordinal === shownOrdinal) {
        return;
      }
      automaticFloorSwitching = automaticFloorSwitching && !userTriggered;
      var cached = itemsByOrdinal[ordinal];

      if (!cached) {
      // Get all floor items (except defaultFloor)
        for (let index = -2; index < 17; index++) {
          if (index !== defaultFloor) {
            if (showHistoryMap){
              handleHistoryTracesOnFloorSwitch(index);
            }
            itemsByOrdinal[index] = function(mapItems) {
              if (expectingOrdinal === index) {
                if (shownOrdinal !== null) {
                  map.removeItems(itemsByOrdinal[shownOrdinal]);
                  map.addItems(mapItems);
                } else {
                  /* The `showItems` function adds the MapKit items to the map and adjusts the region,
                  * so the venue is at the center of the map view.
                  */
                  let reg = map.region;
                  map.showItems(mapItems);
                  map.region = reg;
                  map.rotation = attributes.rotation;
                }
                shownOrdinal = index;
                expectingOrdinal = null;
              }
              itemsByOrdinal[index] = mapItems;
            };
            expectingOrdinal = index;
            await createItemsForOrdinal(index, itemsByOrdinal[index]);
          }
        }
        //Get defaultFloor items (to show it by default)
        itemsByOrdinal[ordinal] = function(mapItems) {
          if (expectingOrdinal === ordinal) {
            if (shownOrdinal !== null) {
              map.removeItems(itemsByOrdinal[shownOrdinal]);
              map.addItems(mapItems);
            } else {
              /* The `showItems` function adds the MapKit items to the map and adjusts the region,
              * so the venue is at the center of the map view.
              */
              let reg = map.region;
              map.showItems(mapItems);
              map.region = reg;
              map.rotation = attributes.rotation;
            }
            shownOrdinal = ordinal;
            expectingOrdinal = null;
          }
          itemsByOrdinal[ordinal] = mapItems;
        };
        expectingOrdinal = ordinal;
        await createItemsForOrdinal(ordinal, itemsByOrdinal[ordinal]);
      } else if (typeof cached !== "function") {
        if (showHistoryMap) handleHistoryTracesOnFloorSwitch(ordinal);
        map.removeItems(itemsByOrdinal[shownOrdinal]);
        map.addItems(cached);
        shownOrdinal = ordinal;
      } else {
        expectingOrdinal = ordinal;
      }
      handleShowMapAnnotations();
      ordinalFloor = ordinal; //?Save current floor flag
    };

    var levelPicker = new LevelPicker(archive, showOrdinal, mapConfigs);
    levelPicker.selectOrdinal(defaultFloor);
  });

  return [ordinalFloor, currentFloorActive, historyTraceStore, showHistoryMap, map];
};
