import {
  ANNOTATION_SUBTITLES,
  OUTSIDE,
} from '../../mapkitJS/IMDF.constants';

export const checkPeopleFilter = (mapkit, peopleVisible) =>{
    mapkit.forEach(dot=>{
      if ((dot.subtitle===ANNOTATION_SUBTITLES.location || dot.subtitle===ANNOTATION_SUBTITLES.callout || dot.subtitle===ANNOTATION_SUBTITLES.smallCallout)) {
        dot.visible = peopleVisible;
      }
    });
  
    return mapkit;
};
  
export const checkCurrentFloorOnlyFilter = (mapkit, filterFloor, floor) =>{
    mapkit.forEach(dot=>{
      if ((dot.subtitle === ANNOTATION_SUBTITLES.callout || dot.subtitle === ANNOTATION_SUBTITLES.location) && dot.data.floor !== floor) {
        dot.visible = false;
      }
      else if ((dot.subtitle===ANNOTATION_SUBTITLES.callout || dot.subtitle===ANNOTATION_SUBTITLES.location) && dot.data.floor === floor) {
        dot.visible = true;
      }
    });
  
    return mapkit;
};
  
export const checkIndoorOnlyFilter = (mapkit, filterIndoor ) =>{
    mapkit.forEach(dot=>{
      if ((dot.subtitle === ANNOTATION_SUBTITLES.callout || dot.subtitle === ANNOTATION_SUBTITLES.location)
      && (dot.data.floor === null || dot.data.floor === OUTSIDE || dot.data.floor === -3)) {
        dot.visible = false;
      }
      else if ((dot.subtitle === ANNOTATION_SUBTITLES.callout || dot.subtitle === ANNOTATION_SUBTITLES.location)
      && dot.data.floor !== null && dot.data.floor !== OUTSIDE && dot.data.floor !== -3) {
        dot.visible = true;
      }
    });
  
    return mapkit;
};
  
export const checkCurrentUserOnlyFilter = (mapkit, filterUser, annotationId ) =>{
    mapkit.forEach(dot=>{
      if (dot.subtitle === ANNOTATION_SUBTITLES.location && dot.title !== annotationId) {
        dot.visible = false;
      }
      else if (dot.subtitle === ANNOTATION_SUBTITLES.location && dot.title === annotationId) {
        dot.visible = true;
      }
    });
  
    return mapkit;
};
  
export const checkAllCalloutsFilterEnabled = (mapkit, calloutVisible) =>{
    mapkit.forEach(dot=>{
      if (dot.subtitle === ANNOTATION_SUBTITLES.callout) {
        dot.visible = calloutVisible;
      }
      else if (dot.subtitle === ANNOTATION_SUBTITLES.smallCallout) {
        dot.visible = false;
      }
    });
  
    return mapkit;
};

export const checkAllCalloutsFilterDisabled = (mapkit, calloutVisible) =>{
    mapkit.forEach(dot=>{
      if (dot.subtitle === ANNOTATION_SUBTITLES.callout ) {
        dot.visible = false;
      }
      else if (dot.subtitle === ANNOTATION_SUBTITLES.smallCallout) {
        dot.visible = false;
      }
    });

  return mapkit;
};
  
export const checkMinimizeCalloutsFilter = (mapkit, smallVisible) =>{
    mapkit.forEach(dot=>{
      if (dot.subtitle === ANNOTATION_SUBTITLES.smallCallout) {
        dot.visible = smallVisible;
      }
      else if (dot.subtitle === ANNOTATION_SUBTITLES.callout) {
        dot.visible = false;
      }
    });
  
    return mapkit;
}