import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Button from '@material-ui/core/Button';
import Icon from "@material-ui/core/Icon";
import Checkbox from '@material-ui/core/Checkbox';

import { saLogin, checkUserEmail, getUserId } from '../../actions/superAdmin/superAdminActions';

//import MicrosoftLoginCmp from '../utilities/microsoftLogin.component';

import { ADMIN_PANEL } from './superAdmin.constants';
import navLogo from './hospital-icons/Logo.png';
import microsoftIcon from './hospital-icons/microsoftIcon.png';
import './superAdminLogin.css';
import hostName from "../utilities/hostName";

const secureUrl = new hostName();
secureUrl.initHostName();

class SuperAdminLogin extends React.Component {

  state = {
    email: '',
    pass: '',
    loginError: false,
    checkedB: false
  }

  componentDidMount() {
    //this.handleSAMLRequest();
  }

  handleSAMLRequest = async () => {
    /*
    if (Object.values(this.props.match.params).length > 0)
     {
      const { id, userId } = this.props.match.params;
      
      this.props.samlDispatchSuperadmin(id, userId).then(async (res) => {
        await this.props.getCampuses();
        await this.props.allUsers();
        await this.props.adminACLS();
        await this.props.getSuperAdmins();
        this.props.history.push('/superAdminSelect');
      }).catch(err => {
        this.setState({ loginError: true });
      })
    }
    */
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.token) {
      const { selectPath } = ADMIN_PANEL;
      this.props.history.push(selectPath);
    }
    //if (nextProps.loginError)
    //this.setState({ loginError: true });
  }

  setInputState = (e, input) => {
    this.setState(
      input === 'email' ? { email: e.target.value } : { pass: e.target.value }
    );
  }

  handleLogin = () => {
    this.doLogin().then(response => {
      if (response.success) {
        this.props.history.push(`/superAdminSelect/${response.userId}`);
      }
    }).catch(err => {
      this.setState({ loginError: true });
    })
  }

  validateEmail(emailString) {
    var regex = /^[a-zA-Z-0-9_]+(\.{1}[a-zA-Z-0-9_]+)?@[a-zA-Z]+?\.[a-z-A-Z]{2,3}(\.[a-z-A-Z]{2,3})?$/;
    return (regex.test(String(emailString)));
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const { classes } = this.props;
    let emailError = <TextField
      tabIndex="1" hinttext="Email Address" onKeyPress={this.keyPressed} floatinglabeltext="Email Address"
      onChange={(e) => this.setInputState(e, 'email')}
      InputProps={{ className: "inputEmailErr" }}
      FormHelperTextProps={{ className: "helpEmail" }}
      helperText="User ID not Found" />;
    let emailNoError = <TextField
      tabIndex="1" hinttext="Email Address" onKeyPress={this.keyPressed} floatinglabeltext="Email Address"
      InputProps={{ className: classes.inputEmail }}
      onChange={(e) => this.setInputState(e, 'email')} />;
    let passError = <TextField tabIndex="2" hinttext="Enter a password" onKeyPress={this.keyPressed} floatinglabeltext="Enter a password" type="password"
      onChange={(e) => this.setInputState(e, 'pass')}
      InputProps={{ className: "inputPasswordErr" }}
      FormHelperTextProps={{ className: "helpPass" }}
      helperText="Wrong Password" />;
    let passNoError = <TextField tabIndex="2" hinttext="Enter a password" onKeyPress={this.keyPressed} floatinglabeltext="Enter a password" type="password"
      InputProps={{ className: classes.inputPassword }}
      onChange={(e) => this.setInputState(e, 'pass')} />;
    let emailSet;
    let passwordSet;
    if (!this.state.loginError) {
      emailSet = emailNoError;
      passwordSet = passNoError;
    } else if (this.state.loginError) {
      emailSet = emailError;
      passwordSet = passError;
    }

    const SvgIcon = (
      <Icon style={{ fontSize: 20, display: 'flex' }}>
        <img src={microsoftIcon} />
      </Icon>
    );

    const { appTitle, panelTitle, emailTitle, loginTitle, passwordTitle, orTitle } = ADMIN_PANEL;

    return (
      <div className="superAdmin">
        <div
          id="webNavv-superAdmin-login-1.0"
          className="super-login"
        >
          <div>
            <div id="webNavv-superAdmin-login-logo-1.0">
              <img src={navLogo} className="logo" />
            </div>
            <p
              id="webNavv-superAdmin-login-title-1.0"
              className="admin-panel"
            >
              {panelTitle}
            </p>
          </div>
          <div className={classes.buttonContainer}>
            <span className={classes.registerText}>{emailTitle}</span>
            {emailSet}
            <span className={classes.registerText}>{passwordTitle}</span>
            {passwordSet}
            <Button
              id="webNavv-superAdmin-login-login_button-1.0"
              className={classes.loginButton}
              onClick={() => this.handleLogin()}
            >
              {loginTitle}
            </Button>
            <div style={{ display: 'flex' }}>
              <span class={classes.line}></span>
              <span className={classes.textLine}>{orTitle}</span>
              <span class={classes.line}></span>
            </div>
            <Button
              id="webNavv-superAdmin-login-microsoft_button-1.0"
              variant="contained"
              className={classes.microsoftButton}
              startIcon={SvgIcon}
              href={`${secureUrl.hostUrl}/api/loginSAML`}
            >
              Sign in with Microsoft
            </Button>
            {
              /*
                <MicrosoftLoginCmp buttonTheme="light" child={childButton} />
              */
            }
            <div
              id="webNavv-superAdmin-login-remember-1.0"
              style={{ display: 'flex' }}
            >
              <Checkbox
                className={classes.checkbox}
                checked={this.state.checkedB}
                onChange={this.handleChange}
                name="checkedB"
                color="primary"
              />
              <span class={classes.remember}>Remember me</span>
            </div>
          </div>
          <Button
            id="webNavv-superAdmin-login-campus_button-1.0"
            className={classes.loginButton}
            style={{ backgroundColor: '#2843A3', position: 'absolute', bottom: 10 }}
            onClick={() => this.props.history.push("/")}
          >
            Campus User
          </Button>
        </div>
      </div>
    );
  }

  keyPressed = (e) => {
    if (e.key === 'Enter') {
      this.doLogin();
    }
  };

  doLogin() {
    return new Promise(async (resolve, reject) => {
      const userCredential = this.state.email.toString().toLowerCase().trim();
      const password = this.state.pass.toString().trim();
      const isEmail = this.emailValidation(userCredential);

      if (isEmail) {
        this.props.checkUserEmail(userCredential).then(response => {
        }).catch(err => {
          reject(null);
        })
      }

      if (this.state.email.length > 0 && this.state.pass.length > 0) {
        this.props.saLogin(userCredential, password, isEmail).then(response => {
          resolve({ success: true, userId: response.userId });
        }).catch(err => {
          reject(null);
        })

      } else {
        reject(null);
      }

    })
  };

  doForgot() {
    this.props.history.push('/passwordReset');
  };

  emailValidation(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };
}

SuperAdminLogin.propTypes = {
  token: PropTypes.string,
  saLogin: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  token: state.auth.token,
  loginError: state.auth.loginError,
  userId: state.auth.userId
})

const styles = {
  root: {
    background: "black"
  },
  loginButton: {
    width: 296,
    height: 45,
    borderRadius: 10,
    boxSizing: 'border-box',
    margin: '20px 0',
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 700,
    fontStyle: 'normal',
    fontWeight: '700',
    background: '#CFCFCF',
    "&:hover": {
      background: '#2843A3'
    },
    border: '0.5px solid #979797',
    textTransform: 'unset',
    color: '#FFFFFF'
  },
  microsoftButton: {
    width: 296,
    height: 45,
    fontSize: 12,
    fontFamily: 'Roboto',
    borderWidth: 1,
    color: '#5E5E5E',
    background: '#FFFFFF',
    borderColor: '#8C8C8C',
    marginTop: 20,
    textTransform: 'unset',
  },
  registerText: {
    color: '#6F6F6F',
    fontFamily: 'Roboto',
    lineHeight: '27.45px',
    fontWeight: 700,
    fontSize: 19,
    marginTop: 20,
  },
  loginMicrosoft: {
    width: '296px',
    height: '45px',
    boxSizing: 'border-box',
    color: 'white',
    fontWeight: '600',
    background: '#CFCFCF',
    "&:hover": {
      background: '#2843A3'
    }
  },
  root: {
    background: "black"
  },
  email: {
    width: '47px !important',
    height: '23px !important',
    fontStyle: 'normal !important',
    fontWeight: 'bold !important',
    fontSize: '19px !important',
    fontFamily: 'Roboto',
    lineHeight: '23px !important',
    color: '#6F6F6F !important',
  },
  password: {
    width: '47px',
    height: '23px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: 'Roboto',
    lineHeight: '23px',
    color: '#6F6F6F',
    boxSizing: 'border-box',
  },
  inputEmail: {
    width: '296px',
    height: '45px',
    background: '#F9FAFF',
    border: '0.5px solid #979797',
    boxSizing: 'border-box',
    borderRadius: '10px',
    "&&&:before": { borderBottom: "none" },
    "&&:after": { borderBottom: "none" },
  },
  inputPassword: {
    width: '296px',
    height: '45px',
    background: '#F9FAFF',
    border: '0.2px solid #979797',
    boxSizing: 'border-box',
    borderRadius: '10px',
    "&&&:before": { borderBottom: "none" },
    "&&:after": { borderBottom: "none" },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 220,
  },
  line: {
    height: 1,
    width: 132,
    backgroundColor: '#bbb',
    float: 'left',
    margin: '14px 0px',
    padding: 'none',
  },
  textLine: {
    float: 'left',
    margin: 5,
    padding: 'none',
    color: '#818181',
    fontSize: 14,
  },
  checkbox: {
    width: 16,
    paddingLeft: 'unset',
  },
  remember: {
    marginTop: 14,
    fontSize: 13,
    fontWeight: 600,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    lineHeight: '15.5px',
    color: '#6F6F6F'
  }
};

export default connect(mapStateToProps, { saLogin, checkUserEmail, getUserId })(withStyles(styles)(withRouter(SuperAdminLogin)));
