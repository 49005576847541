import { env, floorColorPalette } from "./map.constants";

export const getFloorColorByOrdinal = (ordinal) => {
    const host = window.location.hostname;
    const { localhost, devLahey, testHfhs, prod } = env;
    let color = floorColorPalette.primary[18];


    if (!isNaN(ordinal)) {
        switch (ordinal) {
            case -4:
                color = floorColorPalette.primary[21];
                break;
            case -3:
                color = floorColorPalette.primary[20];
                break;
            case -2:
                color = floorColorPalette.primary[0];
                break;
            case -1:
                color = floorColorPalette.primary[19];
                break;
            case 0:
                color = floorColorPalette.primary[1];
                break;
            case 1:
                color = floorColorPalette.primary[2];
                break;
            case 2:
                color = floorColorPalette.primary[3];
                break;
            case 3:
                color = floorColorPalette.primary[4];
                break;
            case 4:
                color = floorColorPalette.primary[5];
                break;
            case 5:
                color = floorColorPalette.primary[6];
                break;
            case 6:
                color = floorColorPalette.primary[7];
                break;
            case 7:
                color = floorColorPalette.primary[8];
                break;
            case 8:
                color = floorColorPalette.primary[9];
                break;
            case 9:
                color = floorColorPalette.primary[10];
                break;
            case 10:
                color = floorColorPalette.primary[11];
                break;
            case 11:
                color = floorColorPalette.primary[12];
                break;
            case 12:
                color = floorColorPalette.primary[13];
                break;
            case 13:
                color = floorColorPalette.primary[14];
                break;
            case 14:
                color = floorColorPalette.primary[15];
                break;
            case 15:
                color = floorColorPalette.primary[16];
                break;
            case 16:
                color = floorColorPalette.primary[17];
                break;
            default:
                return color;
        }
    }

    return color;
};