import { LOGIN, LOGOUT, ERROR, GET_ACL, TOKEN, UPDATE_USERINFO, SET_FEATURE_FLAGS, LASTLOCATION_CONN } from './types';
import axios from 'axios';
import socketioConn from '../../components/utilities/socketioConn';
import hostName from '../../components/utilities/hostName';
import packageJson from '../../../package.json';

const secureUrl = new hostName();
secureUrl.initHostName();

export const updateUserInfo = () => (dispatch, getState) => {
  let currentUserInfo = getState().auth.userInfo;
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (currentUserInfo === undefined) {
    dispatch({
      type: UPDATE_USERINFO,
      payload: userInfo
    });
  }
};

export const doLogin = (email, pass) => (dispatch, getState) => {
  try {
    axios({
      method: 'post',
      url: secureUrl.hostUrl + '/api/accounts/login?include=USER',
      withCredentials: false,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        email: email,
        password: pass,
        ttl: 43200
      }
    }).then(async (response) => {
      if (response.data.id !== '') {
        let acl = await handleGetACL(response.data.userId);
        dispatch({
          type: GET_ACL,
          payload: acl.data.Accesscontrol
        });
        getCampusInfo(response.data.user.campusId).then(async campus => { //getCampusInfo only 1 params
          let userInfo = {
            first_name: response.data.user.first_name,
            last_name: response.data.user.last_name,
            campusId: response.data.user.campusId,
            campusInfo: campus,
            role_name: response.data.user.role_name,
            role: response.data.user.role,
            username: response.data.user.username
          };
          localStorage.setItem("userInfo", JSON.stringify(userInfo));
          await sendLoginParams(response.data.id, response.data.userId, userInfo, acl, dispatch,getState);
        });
      }
    }).catch((error) => {
      dispatch({
        type: ERROR,
        payload: error,
      });
    });
  }catch(error) {
    console.error(`[AUTH ACTIONS] An error ocurred in doLogin: ${error}`);
  }
};

export const logoutPromise = () => (dispatch, getState) => {
  return new Promise( (resolve, reject) => {
    try {
      axios({
        method: 'POST',
        url: secureUrl.hostUrl + '/api/accounts/logout?access_token=' + getState().auth.token,
        data: {}
      }).then((resp) => {
        dispatch({
          type: LOGOUT
        });
        resolve({ sucess: true });
      });
    }catch(error) {
      console.error(`[AUTH ACTIONS] An error ocurred in logoutPromise: ${error}`);
      dispatch({
        type: LOGOUT
      });
      reject({ sucess: false, error });
    }
  });
};

export const handleGetACL = (userId) => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(secureUrl.hostUrl + '/api/access_controls/getACLByUser?userId=' + userId)
      .then(resp => {
        resolve(resp);
      });
    }catch(error){
      console.error(`[AUTH ACTIONS] An error ocurred in handleGetACL: ${error}`);
      reject({ success: false, error: error });
    }
  });
};

export const getACL = (userId) => (dispatch, getState) => {
  handleGetACL(userId).then(resp => {
    dispatch({
      type: GET_ACL,
      payload: resp.data.Accesscontrol
    });
  });
};

const sendLoginParams = async (token, userId, userInfo, acl, dispatch,getState=null) => {
  return new Promise(async (resolve, reject) => {
    try {
      const createSocketio = new socketioConn();
      createSocketio.initSocket();
      const pushNotificationToken = await createSocketio.getToken();
      
      const queryParam = {
        query: {
          userName: userInfo.username,
          configVersion : packageJson.version,
          deviceId: "Desktop",
          campusId: userInfo.campusId,
          userId: userId,
        }
      }
      dispatch({
        type: LOGIN,
        payload: {
          userId: userId,
          token: token,
          userInfo: userInfo,
          socketChat: createSocketio.getChatConn(queryParam),
          pushNotificationToken: pushNotificationToken,
          acl: acl.data.Accesscontrol
        }
      });
      resolve({ success: true });
    } catch (error) {
      console.error(`[AUTH ACTIONS] An error ocurred in sendLoginParams: ${error}`);
      reject({ success: false, error: error });
    }
  });
};

export const connectLastLocationSocket = (people, userId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      const createSocketio = new socketioConn();
      createSocketio.initSocket();
      let contacts = getState().auth.acl.contact;

      dispatch({
        type: LASTLOCATION_CONN,
        payload: {
          socketLastLocation: createSocketio.getLastLocationConn(people, contacts.join(','), userId),
        }
      });
      resolve({ success: true });
    } catch (error) {
      console.error(`[AUTH ACTIONS] An error ocurred in connectLastLocationSocket: ${error}`);
      reject({ success: false, error: error });
    }
  });
};

export const disconnectLastLocationSocket = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try {
      const socket = getState().auth.socketLastLocation;
      if (!!socket){
        socket.close();
        socket.disconnect();
      }

      dispatch({
        type: LASTLOCATION_CONN,
        payload: {
          socketLastLocation: ''
        }
      });
      resolve({ success: true });
    } catch (error) {
      console.error(`[AUTH ACTIONS] An error ocurred in disconnectLastLocationSocket: ${error}`);
      reject({ success: false, error: error });
    }
  });
};


export const setTokenUserId = (token, userId, userInfo) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    let acl = await handleGetACL(userId);
    try {
      await sendLoginParams(token, userId, userInfo, acl, dispatch);
      resolve({ sucess: true });
    } catch (error) {
      console.error(`[AUTH ACTIONS] An error ocurred in setTokenUserId: ${error}`);
      reject({ sucess: false, error });
    }
  });
};

const getCampusInfo = async (campusId) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + `/api/campuses/${campusId}`)
      .then((resp) => {
          resolve(resp.data);
      });
    }catch(error){
      console.error(`[AUTH ACTIONS] An error ocurred in getCampusInfo: ${error}`);
      reject({ sucess: false, error });
    }
  });
};

const getUserInfo = async (userId) => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(secureUrl.hostUrl + `/api/accounts/${userId}`)
      .then((resp) => {

          resolve(resp.data);
      });
    }catch(error){
      console.error(`[AUTH ACTIONS] An error ocurred in getUserInfo: ${error}`);
      reject({ sucess: false, error });
    }
  });
};

export const getAccessTokenMK = (id) => (dispatch, getState) => {
  try{
    axios.get(secureUrl.hostUrl + '/api/accounts/mapKitWebToken?access_token=' + getState().auth.token)
    .then(resp => {
      dispatch({
        type: TOKEN,
        payload: {
          tokenMK: resp.data.token.token
        }
      });
    });
  }catch(error){
    console.error(`[AUTH ACTIONS] An error ocurred in getAccessTokenMK: ${error}`);
  }
};

export const campusLogin = (user) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let webToken = localStorage.getItem("adminToken");
      let userId = user.id;
      axios.post(`${secureUrl.hostUrl}/api/accounts/autologon?userId=${userId}&webtoken=${webToken}`)
        .then(async (response) => {
          let { token } = response.data;
          let acl = await handleGetACL(userId);
          dispatch({
            type: GET_ACL,
            payload: acl.data.Accesscontrol
          });
          getCampusInfo(user.campusId).then(async campus => { //getCampusInfo only 1 params
            let userInfo = {
              first_name: user.first_name,
              last_name: user.last_name,
              campusId: user.campusId,
              campusInfo: campus,
              role_name: user.role_name,
              role: user.role,
            };
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            await sendLoginParams(token.id, userId, userInfo, acl, dispatch);
            resolve(true);
          });
        })
    }catch(error) {
      console.error(`[AUTH ACTIONS] An error ocurred in campusLogin: ${error}`);
      reject(false);
    }
  })
}

export const handleCreateToken = (userId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try{
      axios.post(`${secureUrl.hostUrl}/api/accounts/autologon?userId=${userId.id}&webtoken=${getState().auth.tokenMK}`).then((token) => {
        resolve(token);
      })
    }catch(error) {
      console.error(`[AUTH ACTIONS] An error ocurred in handleCreateToken: ${error}`);
      reject(false);
    }
  });
};

export const handleFindToken = (userId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    try{
      axios.post(`${secureUrl.hostUrl}/api/accounts/findTokenByUser?userId=${userId._id}&webtoken=${getState().auth.token}`).then((token) => {
        resolve(token.data.token);
      })
    }catch(error){
      console.error(`[AUTH ACTIONS] An error ocurred in handleFindToken: ${error}`);
      reject({success:false, error:error})
    }
  });
};

// AD SAML2 Auth
//TODO:
// Make getCampusInfo sharable with local login and SAML login

export const handlCheckToken = (webtoken, campusId) => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    if(!webtoken){
      resolve({ success: false, error: "An error has ocurred, webtoken is empty" })
    }
    try{
      axios({
        method: 'post',
        url: `${secureUrl.hostUrl}/api/accounts/validWebtoken?webtoken=${webtoken}`,
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => {
        const { validToken } = response.data.token;
        if (validToken) {
          const { userId } = response.data.token.res;
          Promise.all([getCampusInfo(campusId), getUserInfo(userId)]).then(async allPromises => {
            let userInfo = {
              first_name: allPromises[1].first_name,
              last_name: allPromises[1].last_name,
              username: allPromises[1].username,
              campusId: allPromises[1].campusId,
              campusInfo: allPromises[0],
              role_name: allPromises[1].role_name,
              role: allPromises[1].role
            };
  
            localStorage.setItem("userInfo", JSON.stringify(userInfo));
            let acl = await handleGetACL(userId);
            await sendLoginParams(webtoken, userId, userInfo, acl, dispatch,getState);
            resolve({ success: true });
          }).catch(error=>{
            reject({ success: false, error: error });
          });
        }
      }).catch(error=>{
        console.log(`An error has ocurred trying to validate web token ${error}`);
      })
    }catch(error){
      console.error(`[AUTH ACTIONS] An error ocurred in handleCheckToken: ${error}`);
      reject({ success: false, error: error });
    }
  });
};

export const expireSession=(webtoken)=>(dispatch, getState) =>{
  
  return new Promise((solve,reject)=>{
    if(!webtoken){
      solve({ success: false, error: "An error has ocurred, webtoken is empty" })
    }
    try{
      axios({
        method: 'post',
        url: `${secureUrl.hostUrl}/api/accounts/validWebtoken?webtoken=${webtoken}`,
        withCredentials: false,
      }).then((response) =>{
          solve(response.data.token.validToken);
      });
    }catch(error){
      console.error(`[AUTH ACTIONS] An error ocurred in expireSession: ${error}`);
      reject(`An error has ocurred in expireSession ${JSON.stringify(error)}`);
    }
  })

}

export const loadConfig = () => {
  return new Promise((resolve, reject) => {
    try{
      axios.get(`${secureUrl.hostUrl}/api/configs`).then((config) => {   
        resolve(config.data[0]);
      })
    }catch(error){
      console.error(`[AUTH ACTIONS] An error ocurred in loadConfig: ${error}`);
      reject({success:false,error:error});
    }
  });
};

export const setFeatureFlags = (remoteConfig) => (dispatch, getState) => {
  dispatch({
    type: SET_FEATURE_FLAGS,
    payload: remoteConfig
  });
};
