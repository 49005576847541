import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Icon } from "@material-ui/core";
import { grey } from '@material-ui/core/colors';
import campusIcon from './img/hosp-btn.png';
import logoutIcon from './img/out_grey.png';
import superAdminIcon from './img/multicampus.png';
import { DEFAULT_PASS } from '../superAdmin/superAdmin.constants';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { doLogin, logoutPromise, campusLogin, handleCreateToken } from '../../actions/auth/authActions';
import { createLog } from '../../actions/logs/logsActions';
import { saLogout, setAdminToken } from '../../actions/superAdmin/superAdminActions';
import { async } from 'rxjs/internal/scheduler/async';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        marginTop: 40,
    },
})((props) => (
    <Menu
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:hover': {
            '& .MuiListItemText-primary': {
                //color: '#FFF',
            },
            '& img': {
                //filter: 'opacity(.5) drop-shadow(0 0 0 blue)',
            },
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20,
            alignSelf: 'baseline',
        },
        '& .MuiListItemText-primary': {
            color: grey[500],
            fontFamily: 'sans-serif',
            fontSize: 14,
            margin: 0,
            padding: 0,
        },
    },
}))(MenuItem);

const StyledItemTitle = withStyles((theme) => ({
    root: {
        '& .MuiListItemText-primary': {
            color: '#000',
        },
    },
}))(ListItemText);

function SuperadminMenu(props) {
    const { campusList, version = '', campusFilter = '', campusSwitch = null, logout } = props;
    const accounts = campusFilter ? props.user.accounts.filter((acc) => acc.campusId !== campusFilter) : [...props.user.accounts];

    const handleSelection = (account) => {
        if (campusSwitch) {
            campusSwitch(account);
        }
        else {
            reloadToDashboard(account);
        }
    }

    const reloadToDashboard = async (account) => {
        props.campusLogin(account).then(result => {
            let userName = account.username.substring(0, account.username.lastIndexOf("_"));
            let campus = props.campusList.find(c => c.id === account.campusId);
            let campusName = campus ? campus.alias : '';
            let logData = {
                campusId: account.campusId,
                action: 'Login',
                description: `Web - Successful autologin using username: ${userName}, campus: ${campusName}, device_id: desktop`,
                timestamp: new Date(),
                userId: account.id
            };
            props.createLog(logData);
            props.history.push('/dashboardCampus');
        });
    }

    const logOut = () => {
        if (accounts.length > 0) {
            if (version) {
                //props.logout();
                props.logoutPromise().then((res) => {
                    //props.history.push('/');             
                    props.saLogout().then((res) => {
                        props.history.push('/');
                    }).catch((error) => {
                        console.log(`sa logout error ${error}`);
                        props.history.push('/');
                    });
                    
                }).catch((error) => {
                    console.log(`logout error ${error}`);
                    props.history.push('/');
                });
                
            }
            else {
                props.saLogout().then((res) => {
                    props.history.push('/');
                }).catch((error) => {
                    console.log(`sa logout error ${error}`);
                    props.history.push('/');
                });
            }
        }
        else {
            props.logoutPromise().then((res) => {
                props.history.push('/');
            }).catch((error) => {
                console.log(`logout error ${error}`);
                props.history.push('/');
            });
        }
    };

    const goToAccessAdmin = () => {
        /*
        props.logoutPromise().then((res) => {
            props.history.push('/superPanel');
        });
        */
       props.goToAccessAdmin();
    };

    const getCampusName = (id) => {
        const name = campusList.filter((campus) => campus.id === id)[0].name;
        return name;
    };

    const renderAdminAccess = () => {
        return (
            <>
                <MenuItem disabled>
                </MenuItem>
                <StyledMenuItem
                    key="superadmin-menu-admin-access"
                    onClick={() => { goToAccessAdmin() } }>
                    <ListItemIcon>
                        <Icon>
                            <img src={superAdminIcon} height={20} width={20} />
                        </Icon>
                    </ListItemIcon>
                    <ListItemText primary="Access Admin Panel" />
                </StyledMenuItem>
            </>
        )
    }

    return (
        <StyledMenu open anchorEl={document.getElementById('user-menu-container')}>
            {version &&
                <MenuItem disabled>{version}</MenuItem>
            }
            {accounts.length > 0 &&
                <MenuItem disabled>
                    <StyledItemTitle primary='Select a hospital:' />
                </MenuItem>
            }

            {accounts.filter(acc => campusList.filter(c => c.id === acc.campusId).length > 0).map((account, index) => {
                const campusName = getCampusName(account.campusId);

                return (
                    <StyledMenuItem
                        key={`superadmin-menu-${index}`}
                        onClick={() => handleSelection(account)}>
                        <ListItemIcon>
                            <Icon

                            >
                                <img src={campusIcon} height={20} width={20} />
                            </Icon>
                        </ListItemIcon>
                        <ListItemText primary={campusName} />
                    </StyledMenuItem>
                )
            }
            )}
            {version &&
                renderAdminAccess()
            }
            <StyledMenuItem
                key="superadmin-menu-logout"
                onClick={() => logOut()}
            >
                <ListItemIcon>
                    <Icon>
                        <img src={logoutIcon} height={20} width={20} />
                    </Icon>
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </StyledMenuItem>
        </StyledMenu>
    );
}

const mapStateToProps = state => ({
    user: state.superAdmin.user,
    auth: state.auth,
    campusList: state.campus.campusList,
})

export default connect(mapStateToProps, { doLogin, saLogout, logoutPromise, handleCreateToken, setAdminToken, campusLogin, createLog })((withRouter(SuperadminMenu)));