import axios from "axios";
import { ALL_CONTACTS, SELECT_USER, SET_NAMES, LAST_LOCATION,DETAIL_CONTACTS, DETAIL_NAME } from './types';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

export const allContacts = () => (dispatch, getState) => {
  try{
    axios
    .get(
      secureUrl.hostUrl +
        "/api/contacts/groups"
    )
    .then((resp) => {
      dispatch({
        type: ALL_CONTACTS,
        payload: resp.data,
      });
    });
  }catch(error){
    console.error("[CONTACT ACTIONS] An error ocurred in allContacts: ", error);
  }

};
export const selectUser = (user) => (dispatch, getState) =>{
  dispatch({
    type: SELECT_USER,
    payload: user
  });
};
export const setNames = (names) => (dispatch, getState) =>{
  dispatch({
    type: SET_NAMES,
    payload: names
  });
};
export const registerLastLocation = (location) => (dispatch, getState) =>{
  dispatch({
    type: LAST_LOCATION,
    payload: location
  });
};
export const detailsContact = (userId,contactId) => (dispatch, getState) =>{
  try{
    axios
    .get(
      secureUrl.hostUrl +
        "/api/contact_events/details?userId="+userId+"&contactId="+contactId
    )
    .then((resp) => {
      dispatch({
        type: DETAIL_CONTACTS,
        payload: resp.data.contacts,
      });
    });
  }catch(error){
    console.error("[CONTACT ACTIONS] An error ocurred in detailsContact: ", error);
  }

};
export const setDetailsName = (name) => (dispatch, getState) =>{
  dispatch({
    type: DETAIL_NAME,
    payload: name
  });
};
// export const directContacts = (userId) => (dispatch, getState) => {
//   axios
//     .get(
//       secureUrl.hostUrl +
//         `/contacts/dayContacts?userId=${userId}`
//     )
//     .then((resp) => {
//       dispatch({
//         type: DIRECT_CONTACTS,
//         payload: resp.data,
//       });
//     });
// };
