import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import LoginFunction from './components/login/loginFunction.component';
import Dashboard from './components/dashboard/dashboard.component';
import WayFinding from './components/mappedIn/mappedIn.component';

import SuperAdminLogin from './components/login/superAdminLogin';
import SuperAdminSelect from './components/login/superAdminSelect';
import adminDashboard from './components/adminDashboard/adminDashboard';
import SuperPanel from './components/superAdmin/superPanel.component';

import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';

import {
  NotificationProvider,
} from 'amazon-chime-sdk-component-library-react';
import { AuthProvider } from './Providers/AuthProvider';
import { MessagingProvider } from './Providers/ChatMessagesProvider';

import ResetPassword from './components/password-reset/password_reset';
import NewPassword from './components/new-password/new_password.component';

import { FlagsProvider } from './components/firebase/firebaseProvider';

import { Provider } from 'react-redux';
import Store from './store';

const muiTheme = getMuiTheme({
  palette: {
    textColor: '#000000',
    primary1Color: '#2843A3',
    primary2Color: '#2843A3',
  },
  appBar: {
    height: 50,
  },
});

const App = () => {
  /*
    state = {
      token: '',
      userId: '',
    }
  
    _setTokenAndUser = (arg) => {
      this.setState({
        token: arg.token,
        userId: arg.userId
      })
    }
    */


  return (
    <Router>
      <FlagsProvider defaults={{}}>
      <AuthProvider>
        <Provider store={Store}>
          <MessagingProvider>
            <NotificationProvider>
              <MuiThemeProvider muiTheme={muiTheme}>
                <Switch>
                  <Route exact path="/" render={(props) => (<LoginFunction {...props} />)} />
                  <Route exact path="/login/:id/:campusId/" render={(props) => (<LoginFunction {...props} />)} />
                  <Route exact path="/dashboard" render={(props) => (<Dashboard {...props} isSuperadmin={false} />)} />
                  <Route path="/dashboardCampus" render={(props) => (<Dashboard {...props} isSuperadmin={true} />)} />
                  <Route exact path="/wayfinding" render={(props) => (<WayFinding {...props} />)} />
                  <Route exact path="/adminDashboard" render={(props) => (<adminDashboard {...props} />)} />
                  <Route exact path="/passwordReset" render={(props) => (<ResetPassword {...props} />)} />
                  <Route path="/newPassword" render={(props) => (<NewPassword {...props} />)} />
                  <Route exact path="/superAdminLogin" render={(props) => (<SuperAdminLogin {...props} />)} />
                  <Route exact path="/superAdminSelect/:id/:token?" render={(props) => (<SuperAdminSelect {...props} />)} />
                  <Route exact path="/superPanel" render={(props) => (<SuperPanel {...props} />)} />
                </Switch>
              </MuiThemeProvider>
            </NotificationProvider>
          </MessagingProvider>
        </Provider>
      </AuthProvider>
      </FlagsProvider>
    </Router>
  );
}

export default App;
