
export const checkCurrentMapScenario = (nextProps, props, mapkit, actions) =>{
    //? User History
    if (fromHistoryMap_toActiveMap(nextProps, props)) {
        actions[0]();
    }
    if (fromHistoryTable_toHistoryMap(nextProps, props)) {
        actions[1]();
    }
    
    //? Job History
    if (fromJobHistory_toJobHistoryMap(nextProps, props)) {
        actions[2]();
    }
    if (fromJobHistoryMap_toActiveMap(nextProps, props)) {
        actions[3]();
    }
    if (checkShowCalloutsInJobHistoryMap(nextProps, props)) {
        actions[4](nextProps);
    }
    
    //? Equipment
    if (props.equipmentSelected!=='' && nextProps.equipmentSelected==='' ) {
        actions[0]();
    }  
    if (props.equipmentMapFilter && !nextProps.equipmentMapFilter && !!mapkit) {
        actions[5]();
    }
    if (props.viewProps === 'previewEquipment' && nextProps.viewProps !== 'previewEquipment') {
        actions[6]();
    }
    
    //? Track user
    if (fromTrackUser_toActiveMap(nextProps, props)){
        actions[7]();
    }
    if (fromActiveMap_toTrackUser(nextProps, props)) {
        actions[8](nextProps);
    }
    
    // Connect/disconnect from socket channels based on 'People' map filter (from tracking a user/device)
    if (props.peopleMapFilter && !nextProps.peopleMapFilter && (nextProps.viewProps === 'transporter' || nextProps.viewProps === 'transporterPhone')){
        actions[9]();
    }
    if(!props.peopleMapFilter && nextProps.peopleMapFilter && (nextProps.viewProps === 'transporter' || nextProps.viewProps === 'transporterPhone')){
        actions[10]();
    }
    
    // Connect/disconnect from socket channels based on 'People' map filter (from active map)
    if (props.peopleMapFilter && !nextProps.peopleMapFilter && nextProps.viewProps === ''){
        actions[11]();
    }
    if(!props.peopleMapFilter && nextProps.peopleMapFilter && nextProps.viewProps === ''){
        actions[12]();
    }
}

const fromTrackUser_toActiveMap = (nextProps, prevProps) => !!prevProps.transporterFound && !nextProps.transporterFound && !!prevProps.transporterPhone && !nextProps.transporterPhone;

const fromActiveMap_toTrackUser = (nextProps, prevProps) => !prevProps.transporterFound && !!nextProps.transporterFound && !prevProps.transporterPhone && !!nextProps.transporterPhone;

const fromHistoryMap_toActiveMap = (nextProps, prevProps) =>{
    const prevHistoryRoute = !!prevProps.historyRoute ? true : false;
    return prevHistoryRoute && !nextProps.historyRoute;
}

const fromHistoryTable_toHistoryMap = (nextProps, prevProps) =>{
    const prevHistoryRoute = !!prevProps.historyRoute ? true : false;
    return !prevHistoryRoute && nextProps.historyRoute;
}

const fromJobHistory_toJobHistoryMap = (nextProps, prevProps) =>{
    const prevJobTasks = !!prevProps.jobTasks ? true : false;
    return !prevJobTasks && !!nextProps.jobTasks && nextProps.specimenTracking.Permission
}

const fromJobHistoryMap_toActiveMap = (nextProps, prevProps) =>{
    const prevJobTasks = !!prevProps.jobTasks ? true : false;
    return prevJobTasks && !nextProps.jobTasks && nextProps.specimenTracking.Permission
}

const checkShowCalloutsInJobHistoryMap = (nextProps, prevProps) =>{
    return prevProps.animationShowCallouts !== nextProps.animationShowCallouts && nextProps.specimenTracking.Permission
}