import {SAVE_ACL, UPDATE_ACL, FETCH_ACLS, DELETE_ACL, FETCH_ROLES, FETCH_ALL_ACLS, ADMIN_ACLS} from './types';
import axios from 'axios';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();
export const saveACL = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject)=>{
    try{
      axios.post(
        `${secureUrl.hostUrl}/api/access_controls?access_token= ${getState().auth.token}` , data).then(resp=>{
        var theACL = getState().acl.acls;
        theACL.push(resp.data);
        dispatch({
          type: SAVE_ACL,
          payload: theACL,
        });
        resolve(true);
      })
    }catch(error){
      console.error("[ACL ACTIONS] An error ocurred in saveACL: ", error);
      reject(error);
    }
  });
};

export const updateACL = (data) => (dispatch, getState) => {
  return new Promise((resolve, reject)=>{
    try{
      axios.patch(
        secureUrl.hostUrl + '/api/access_controls/' + data.id + '?access_token=' + getState().auth.token,
        data
      ).then(resp=>{
        var theACL = getState().acl.acls;
        theACL = theACL.map(e=>{
          if (e.id === resp.data.id)
            e = resp.data;
          return e;
        });
        dispatch({
          type: UPDATE_ACL,
          payload: theACL,
        });
        resolve(theACL);
      })
    }catch(error){
      console.error("[ACL ACTIONS] An error ocurred in updateACL: ", error);
      reject(error);
    }
  });
};

export const fetchACLS = () => async(dispatch, getState) => {  
  let access_token = localStorage.getItem("userToken");
  let userInfo = JSON.parse(localStorage.getItem("userInfo"));
  let campusId =userInfo.campusId;

  try{
    axios.get(`${secureUrl.hostUrl}/api/access_controls/getAccessbyCampus?campusId=${campusId}&access_token=${access_token}`).then((resp)=>{
      if (!!resp.data.access_control && resp.data.access_control.length > 0) {
        dispatch({
          type: FETCH_ACLS,
          payload: resp.data.access_control,
        });
      }
    });
  }catch(error){
    console.error("[ACL ACTIONS] An error ocurred in fetchACLS: ", error);
  }
};

export const fetchAllACLS = () => async(dispatch, getState) => {  
  let access_token = localStorage.getItem("userToken") || getState().auth.tokenMK;

  try{
    axios.get(secureUrl.hostUrl + `/api/access_controls?access_token=${access_token}`).then((resp)=>{
      if (resp.data) {
        const acList = resp.data.map((item) => {
          const itemResult = {
            id: item.id,
            role: item.role,
            campusId: item.campusId
          }
          return itemResult;
        })
        dispatch({
          type: FETCH_ALL_ACLS,
          payload: acList,
        });
      }
    });
  }catch(error){
    console.error("[ACL ACTIONS] An error ocurred in fetchAllACLS: ", error);
  }
};

export const fetchRoles = () => async(dispatch, getState) => {  
  let access_token = localStorage.getItem("userToken") || getState().auth.tokenMK;

  try{
    axios.get(secureUrl.hostUrl + `/api/access_controls?access_token=${access_token}`).then((resp)=>{
      if (resp.data) {
        const roleList = resp.data.map((item) => item.role).filter((v, i, a) => a.indexOf(v) === i).sort();
        dispatch({
          type: FETCH_ROLES,
          payload: roleList,
        });
      }
    });
  }catch(error){
    console.error("[ACL ACTIONS] An error ocurred in fetchRoles: ", error);
  }
};

export const adminACLS = () => async(dispatch, getState) => {
  // let userInfo = JSON.parse(localStorage.getItem("userInfo"));

  try{
    axios.get(secureUrl.hostUrl + `/api/access_controls`).then((resp)=>{
      if (resp.data.length > 0) {
        dispatch({
          type: ADMIN_ACLS,
          payload: resp.data.filter(acl=>acl.role==="Admin"),
        });
      }
    });
  }catch(error){
    console.error("[ACL ACTIONS] An error ocurred in adminACLS: ", error);
  }

};

export const deleteACL = (id) => (dispatch, getState) => {
  return new Promise((resolve, reject)=>{
    try{
      axios.delete(secureUrl.hostUrl + '/api/access_controls/' + id + '?access_token=' + getState().auth.token).then(resp=>{
        let acls = getState().acl.acls.filter(a=>a.id !== id);
        dispatch({
          type: DELETE_ACL,
          payload: acls,
        });
        resolve(true);
      })
    }catch(error){
      console.error("[ACL ACTIONS] An error ocurred in deleteACL: ", error);
      reject(error);
    }
  });
};
