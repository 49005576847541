import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import axios from "axios";
import { Toolbar } from 'material-ui/Toolbar/';
import Menu from 'material-ui/Menu';
//import SettingsIcon from 'material-ui/svg-icons/action/supervisor-account';
import SvgIcon from '@material-ui/core/SvgIcon';
import SettingsIcon from 'material-ui/svg-icons/action/settings';
import MenuItem from 'material-ui/MenuItem';

import './dashboard.component.css';
import MapContainer from './../map/mapcontainer.component';
import AllContacts from '../../components/contact/allContacts/allContacts.component';
import DirectContacts from '../../components/contact/directContacts/directContacts.component';
import DetailsComponent from '../../components/contact/details/details.component';
import SettingsMenu from '../main-settings/menu.component';
import Chat from './../chat/chat.component';
import { setFeatureFlags, logoutPromise, getACL, setTokenUserId, campusLogin, updateUserInfo, expireSession } from '../../actions/auth/authActions';
import { createLog } from '../../actions/logs/logsActions';
import { listUsers } from '../../actions/user/userActions';
import { validateUserName } from '../utilities/userValidation';
import AccessControl from '../acl/AccessControl';
import {
    changeView,
    changeDrawer,
    changePeopleFilter,
    changeEquipmentFilter,
    setCurrentMenuValue,
    openBroadcastChat,
    unreadBroadcastChat,
    changeZoomToEquipment,
    setMapConfigurations
} from '../../actions/views/viewsActions';
import { resetNewRoute } from '../../actions/directions/directionsActions';
import { allContacts } from '../../actions/contacts/contactActions';
import { filterUsers } from '../../actions/contactUserList/contactUserListActions';
import { fetchACLS } from '../../actions/acl/aclActions';
import { sendLoginAdminParams, allUsers, getSuperAdmins } from '../../actions/superAdmin/superAdminActions';
import { getCampuses } from '../../actions/campus/campusActions';
import { adminACLS } from '../../actions/acl/aclActions';
import VerticalTabs from './sideBar/sideBar.component';
import TeamManager from "../teamManager/teamManager.component";
import HistoryTable from "../history-table/history.component";
import EquipmentHistory from "../equipment/equipment-history.component";
import PhonesControl from "./../fleet-manager/phones.component";
import AccessControlComponent from '../admin/accessControl/accesscontrol.component';
import UsersControl from '../admin/users/users.component';
import EquipmentControl from '../admin/equipment/equipmentTable.component';
import HistoryInfo from './../filters/historyTrace/historyMap.component';
import packageJson from '../../../package.json';
import MasterAlert from '../utilities/alert.component';
import LogsTable from '../main-settings/logs/logsTable.component';
import LoggedUser from '../user-account/loggedUser.component';
import SuperadminMenu from '../user-account/superadminMenu.component';
import DashboardMenu from '../user-account/dashboardMenu.component';
import Wayfinding from '../mappedIn/mappedIn.component';
import JobsBoard from '../workflowAutomation/jobsBoard/jobsBoard.component';
import JobsHistory from '../workflowAutomation/jobsHistory/jobsHistory.component';
import RemoteConfig from './remoteConfig.component';
import { EMPTY_STRING, View, LoggedItem, MapValues, Tabs, MenuValues, expire_time_hours, expire_time_secs, EXPIRE_DATE, EXPIRE_TIME } from './dashboard.constants';


const ADMIN_TOKEN = "adminToken";
const ADMIN_INFO = "adminInfo";

class Dashboard extends React.Component {
    constructor(props) {
        const { MAP, BLOCK } = MapValues;

        super(props);

        this.state = {
            currentView: MAP,
            historyRoute: false,
            transporterFound: false,
            snackBarOpen: false,
            greetingMsg: true,
            openMenu: false,
            openHistoryInfo: false,
            userOnline: true,
            adminPanel: false
        }
        this.mapkit = EMPTY_STRING;
        this.shouldRenderMap = BLOCK;
        this.snackBarMsg = EMPTY_STRING;
        this.checkIfLogged(props);
        this.userOnline = true;
        this.logInParamsSet = false;
    }

    handleOpenSnackBar = () => {
        this.snackBarMsg = <div style={{ display: 'inline' }}><div style={{ display: 'inline', fontWeight: 'bold' }}>GeoJSON</div> copied!</div>;
        this.setState({ snackBarOpen: true });
    };

    handleCloseSnackBar = () => {
        this.setState({ snackBarOpen: false, greetingMsg: false });
    };

    handleOpenMapSnackBar = () => {
        this.setState({ snackBarOpen: true });
    };

    handleCloseMapSnackBar = () => {
        this.setState({ snackBarOpen: false, greetingMsg: false });
    };

    loadMapkitJSScript = () => {
        //console.info(`3.-[MAP] Loading MapKit js!`);
        return new Promise((resolve, reject) => {
            const s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://cdn.apple-mapkit.com/mk/5.x.x/mapkit.js';
            document.body.appendChild(s);
            //console.info(`3.1-[MAP] MapKit js loaded!`);
            resolve(s);
        })
    }

    async componentDidMount() {
        const { USER_TOCKEN } = LoggedItem;
        //console.info(`1.-[MAP] Loading map config`);
        if (!Object.keys(this.props.mapConfigs).length) {
            const mConfig = await this.props.setMapConfigurations();
        }
        //console.info(`2.-[MAP] Map config loaded!`);
        await this.loadMapkitJSScript();
        if (this.props.users === null || this.props.users === undefined) {
            this.props.listUsers();
        }
        this.props.filterUsers();
        this.props.allContacts();
        if (this.props.acls === null || this.props.acls === undefined) {
            this.props.fetchACLS();
        }
        /*
        if (this.props.isSuperadmin) {
            if (!this.props.user.id) {
                this.loadSuperAdminData();
            }
        }
        */
        this.handleExpireSessionAut();
        //first call in mount componet
        try {
            const userToken = localStorage.getItem(USER_TOCKEN);
            await this.handleCallExpireSession(userToken);
        } catch (error) {
            console.error(`[DASHBOARD] An error has ocurred trying to call expire session first check ${error}`)
        }

        if (this.props.userInfo === '') {
            this.props.updateUserInfo();
        }
        try {
            this.props.socketChat.on('ping', () => {
                console.info(`[DASHBOARD] Socket chat connection,  package recieved`);
                this.setState({ userOnline: true });
            });
            this.props.socketChat.on('disconnecting', reason => {
                console.error(`[DASHBOARD] Socket chat connection,  disconnecting ${reason}`);
                this.setState({ userOnline: false });
            });
            this.props.socketChat.on('connect_error', error => {
                console.error(`[DASHBOARD] Socket chat connection,  Connect Error ${error}`);
                this.setState({ userOnline: false });
            });
            this.props.socketChat.on('reconnect_attempt', attempt => {
                console.error(`[DASHBOARD] Socket chat connection,  Reconnect attempt ${attempt}`);
                this.setState({ userOnline: false });
            });
        } catch (error) {
            console.error(`[DASHBOARD] there was an error triying to connect to socket chat ${error}`)
        }
        this.handleExpireSession();
        //this.handleOpenBroadcastWindow();
        console.log("Feature Flags Version: ", this.props.featureFlags.Version);
    }

    handleOpenBroadcastWindow = () => {
        try {
            this.props.socketChat.on("bcst-messages", (broadcast) => {
                if (!!broadcast) {
                    this.props.openBroadcastChat(true);
                    if (!!broadcast.message && !this.props.unreadBroadcast) {
                        this.props.unreadBroadcastChat(true);
                    }
                }
            });
        } catch (error) {
            console.error(`[DASHBOARD] Couldn't open Broadcast window on new msg ${error}`)
        }
    }

    handleExpireSession = () => {
        try {
            this.props.socketChat.on('user disconnect', userDisconnected => {
                if (this.handleValidateUser(userDisconnected)) {
                    this.handleRemoveToken();
                }
            });
        } catch (error) {
            console.error(`[DASHBOARD] there was an error triying to connect to socket chat ${error}`)
        }
    }

    handleRemoveToken = () => {
        const { USER_ID, USER_TOCKEN, USER_INFO } = LoggedItem;
        localStorage.removeItem(USER_ID);
        localStorage.removeItem(USER_TOCKEN);
        localStorage.removeItem(USER_INFO);
        if (this.props.isSuperadmin) {
            localStorage.clear();
        }
        sessionStorage.clear();
        window.location.href = "/";
    }

    handleExpireSessionAut = () => {
        try {
            if (!sessionStorage.getItem(EXPIRE_DATE)) {
                sessionStorage.setItem(EXPIRE_DATE, new Date());
                sessionStorage.setItem(EXPIRE_TIME, expire_time_secs);
            }

            let intervalSession = setInterval(async () => {
                let expire_date = sessionStorage.getItem(EXPIRE_DATE)
                let time_reminder = moment.duration(moment().diff(expire_date));
                let hours_connected = time_reminder.hours();
                if (this.isExpireSessionCandidate(hours_connected) && !!this.props.access_token) {
                    let token = await this.handleCallExpireSession(this.props.access_token);
                    if (!token.validToken) {
                        clearInterval(intervalSession);
                    }
                }
            }, 1000);
        } catch {
            console.error(`An error has ocurred trying to check if the user is already expired`)
        }

    }

    isExpireSessionCandidate = (hours) => {
        return expire_time_hours <= hours
    }

    handleCallExpireSession = async (webToken) => {
        return new Promise(async (solve, reject) => {
            try {
                let isValidToken = await this.props.expireSession(webToken);
                if (!isValidToken) {
                    this.handleRemoveToken();
                    solve({ validToken: false });
                } else {
                    solve({ validToken: true });
                }
            } catch (err) {
                reject(err);
            }
        })
    }

    handleValidateUser = (userId) => this.props.userId === userId

    loadInitialData = () => {
        this.fetchUsers();
        this.props.allContacts();
        this.props.fetchACLS();
    }

    loadSuperAdminData = () => {
        let adminToken = localStorage.getItem(ADMIN_TOKEN);
        if (adminToken) {
            let adminInfo = JSON.parse(localStorage.getItem(ADMIN_INFO));
            this.props.sendLoginAdminParams(adminToken, adminInfo).then(async (result) => {
                const { getCampuses, allUsers, adminACLS, getSuperAdmins } = this.props;
                Promise.all([getCampuses(), allUsers(), adminACLS(), getSuperAdmins()]).then(values => {
                    console.info("[DASHBOARD] loadSuperAdminData:", values);
                }).catch(reason => {
                    console.error("[DASHBOARD] An error ocurred in loadSuperAdminData:", reason);
                });
            }).catch(err => {
                console.error("[DASHBOARD] An error ocurred in loadSuperAdminData:", err);
            })
        }
    }

    campusSwitch =  (account) => {
        this.props.campusLogin(account).then(async (result) => {
            const mConfig = await this.props.setMapConfigurations();
            let userName = account.username.substring(0, account.username.lastIndexOf("_"));
            let { campusInfo } = this.props.userInfo;
            let campusName = campusInfo ? campusInfo.alias : '';
            let logData = {
                campusId: account.campusId,
                action: 'Login',
                description: `Web - Successful autologin using username: ${userName}, campus: ${campusName}, device_id: desktop`,
                timestamp: new Date(),
                userId: account.id
            };
            this.props.createLog(logData);
            this.props.history.push('/dashboardCampus');
        });
    }

    goToAccessAdmin = () => {
        this.setState({ adminPanel: true });
        this.props.logoutPromise();
    };

    componentDidUpdate(prevProps, prevState) {
        const { HISTORY, TABLE, MAP } = View;
        const { HT } = MenuValues;

        if (this.state.adminPanel) {
            this.props.history.push('/superPanel');
            return;
        }


        this.checkIfLogged(this.props.userInfo);
        if (this.props.viewProps === HISTORY && this.state.open && this.props.currentView === MAP) {
            this.setState({ openMenu: false });
        }

        if (this.props.acl === undefined) {
            //this.props.getACL();
            this.fetchUsers();
        }

        if (this.props.currentView === TABLE && prevProps.currentView === MAP && this.props.viewProps === HISTORY && prevProps.viewProps === HISTORY) {
            this.setCurrentMenuVal(HT);
        }

        if (this.props.userInfo === '') {
            this.props.updateUserInfo();
        }

        if (!!this.props.userId && this.props.userId !== prevProps.userId) {
            this.loadInitialData();
            if (this.props.isSuperadmin) {
                if (!this.props.user.id) {
                    this.loadSuperAdminData();
                }
            }
        }
    }

    checkIfLogged = async (prop) => {
        const { USER_ID, USER_TOCKEN, USER_INFO } = LoggedItem;

        if (prop === EMPTY_STRING) {
            let userId = localStorage.getItem(USER_ID);
            let userToken = localStorage.getItem(USER_TOCKEN);
            let userInfo = JSON.parse(localStorage.getItem(USER_INFO));
            if (!userId) {
                window.location.href = "/";
            } else {
                if (!this.logInParamsSet) {
                    this.logInParamsSet = true;
                    await this.props.setTokenUserId(userToken, userId, userInfo);
                }

                if (this.props.acl === null || this.props.acl === undefined) {
                    this.props.getACL(userId);
                }
            }
        }
    }

    handleToggle = () => this.props.changeDrawer();

    logOut = () => {
        this.props.logoutPromise().then((resp) => {
            window.location.href = "/";
        });
    }

    fetchUsers = () => {
        this.props.listUsers();
        this.props.filterUsers();
    }

    renderMainContent = () => {
        const { MAP, HISTORY, TABLE, CONTACT, DIRECT_CONTACT, SETTINGS, MAIN_SETTINGS, LOGS, ADMIN, USERS, ACCESS_CONTROL, EQUIPMENT, DETAILS_CONTACT, FLEET_MANAGER, TEAM_MANAGER, EQUIPMENT_HISTORY, WAYFINDING, WORKFLOW_AUTOMATION, JOBS_BOARD, JOBS_HISTORY } = View;
        const { TAB_A, TAB_B, TAB_C } = Tabs;

        switch (this.props.currentView) {
            case MAP:
                this.handleDisplayMap(MAP)
                break;
            case HISTORY:
                this.handleDisplayMap(HISTORY)
                break;
            case TABLE:
                this.handleDisplayMap(TABLE)
                if (this.props.viewProps === TEAM_MANAGER) {
                    return <TeamManager tab={TAB_A} />
                }
                if (this.props.viewProps === HISTORY) {
                    return <HistoryTable tab={TAB_B} />
                }
                if (this.props.viewProps === FLEET_MANAGER) {
                    return <PhonesControl tab={TAB_C} />
                }
                if (this.props.viewProps === EQUIPMENT_HISTORY) {
                    return <EquipmentHistory />
                }

                break;
            case CONTACT:
                this.handleDisplayMap(CONTACT)
                const allContacts = this.props.contacts;
                return (<AllContacts allContacts={allContacts} users={this.props.users} open={this.props.open} />)
            case DIRECT_CONTACT:
                this.handleDisplayMap(DIRECT_CONTACT);
                return (<DirectContacts allContacts={this.props.directContactsforAUser} names={this.props.nameList} user={this.props.userSelected} />)
            case SETTINGS:
                this.handleDisplayMap(SETTINGS);
                if (this.props.viewProps === MAIN_SETTINGS) {
                    return (<SettingsMenu />);
                }
                if (this.props.viewProps === LOGS) {
                    return (<LogsTable />);
                }
                break;
            case WORKFLOW_AUTOMATION:
                this.handleDisplayMap(WORKFLOW_AUTOMATION);
                if (this.props.viewProps === JOBS_BOARD) {
                    return (<JobsBoard />);
                }
                if (this.props.viewProps === JOBS_HISTORY) {
                    return (<JobsHistory />);
                }
                break;
            case ADMIN:
                this.handleDisplayMap(ADMIN);
                if (this.props.viewProps === USERS) {
                    return <UsersControl />
                }
                if (this.props.viewProps === ACCESS_CONTROL) {
                    return <AccessControlComponent />
                }
                if (this.props.viewProps === EQUIPMENT) {
                    return <EquipmentControl />
                }
                break;
            case DETAILS_CONTACT:
                this.handleDisplayMap(DETAILS_CONTACT);
                return <DetailsComponent />
            case WAYFINDING:
                return <Wayfinding />
            default:
                break;
        }
    }

    handleDisplayMap = (view) => {
        const { HISTORY, MAP } = View;
        const { BLOCK, NONE } = MapValues;

        if (view === MAP || view === HISTORY) {
            this.shouldRenderMap = BLOCK;
        }
        else {
            this.shouldRenderMap = NONE;
        }
    }

    //TODO: Validation for Admin to show/hide menu
    renderAdvancedTools = () => {
        return (
            <Menu className="advance-tools">
                <AccessControl wrapped={MenuItem} primaryText="Admin Panel" title="Admin Panel" leftIcon={<SvgIcon>
                    <path d="M15 6a1.54 1.54 0 0 1-1.5-1.5 1.5 1.5 0 0 1 3 0A1.54 1.54 0 0 1 15 6zm-1.5-5A5.55 5.55 0 0 0 8 6.5a6.81 6.81 0 0 0 .7 2.8L1 17v2h4v-2h2v-2h2l3.2-3.2a5.85 5.85 0 0 0 1.3.2A5.55 5.55 0 0 0 19 6.5 5.55 5.55 0 0 0 13.5 1z" />
                </SvgIcon>} onClick={() => this.props.changeView('admin')} />
                <MenuItem primaryText="Settings" leftIcon={<SettingsIcon />} disabled={true} />
            </Menu>
        )
    }

    toggleCurrentView = () => {
        const { MAP, TABLE } = View;

        if (this.props.currentView === TABLE) {
            this.props.changeView(MAP, EMPTY_STRING);
        }
        else {
            this.props.changeView(TABLE);
        }
    }

    /**
     * This method changes the view and sets the specific parameter depending on the 'prop' value.
     *
     * This applies mostly to the Map view, what can recieve whether a trace or an specific transporter
     * to be displayed
     * @param {String} view
     * @param {String} prop
     * @param {boolean} data
     * @deprecated
     */
    changeView = (view, prop = EMPTY_STRING, data = false) => {
        const { HISTORY, MAP, TRANSPORTER } = View;

        if (view === MAP) {
            if (prop === HISTORY) {
                this.setState({ currentView: view, historyRoute: data, transporterFound: false });
            }
            if (prop === TRANSPORTER) {
                this.setState({ currentView: view, transporterFound: data, historyRoute: false, allCallouts: true });
            }
            else {
                this.setState({ currentView: view, transporterFound: false, historyRoute: false });
            }
        } else {
            this.setState({ currentView: view, open: false });
        }
    }

    setCurrentMenuVal = (item) => {
        const { AC, TM, FM, HT, EQ } = MenuValues;

        if (item === AC || TM || FM || HT || EQ) {
            this.handleToggle();
        }
        this.props.setCurrentMenuValue();
    }

    toggleUserMenu = () => {
        this.setState({ openMenu: !this.state.openMenu });
    }

    closeUserMenu = () => {
        if (this.state.openMenu) {
            this.setState({ openMenu: false });
        }
    }

    render() {
        const { snackBarOpen, greetingMsg } = this.state;
        const firstName = this.props.userInfo.first_name;
        const lastName = this.props.userInfo.last_name;
        const role = this.props.userInfo.role_name ? this.props.userInfo.role_name : EMPTY_STRING;
        const campus = this.props.userInfo.campusInfo ? this.props.userInfo.campusInfo.name : EMPTY_STRING;
        const campusId = this.props.userInfo.campusId ? this.props.userInfo.campusId : EMPTY_STRING;
        const { HISTORY, WORKFLOW_AUTOMATION, MAP } = View;
        const isSuperadmin = this.props.isSuperadmin ? true : false;

        if (!!this.props.acl && !!this.props.acl.views && this.props.acl.views[1].access && this.props.userInfo.campusInfo !== undefined && greetingMsg && !snackBarOpen) {
            this.snackBarMsg = <div style={{ display: 'inline' }}>Hi!, you're in <div style={{ display: 'inline', fontWeight: 'bold' }}>{this.props.userInfo.campusInfo.name}</div></div>;
            this.handleOpenMapSnackBar();
        }
        if (!!this.props.acl && !!this.props.acl.views && this.props.acl.views[1].access === false && this.props.currentView === 'map' && !snackBarOpen) {
            this.snackBarMsg = <div style={{ display: 'inline' }}>Current Team can't access to <div style={{ display: 'inline', fontWeight: 'bold' }}>Active Map</div> view</div>;
            this.handleOpenMapSnackBar();
        }
        if (this.props.currentView !== 'map' && snackBarOpen) {
            this.handleCloseMapSnackBar();
        }
        return (
            <div key={campusId}>
                <RemoteConfig setFeatureFlags={this.props.setFeatureFlags} />
                {this.props.userInfo.campusInfo !== undefined &&
                    <React.Fragment>
                        <Toolbar className="nav-bar" >
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ display: 'flex', marginLeft: "80vw" }}>
                                    {this.state.openMenu && !isSuperadmin &&
                                        <div style={{ zIndex: "99 !important", width: '0px' }}>
                                            <DashboardMenu version={`v${packageJson.version}`} logout={this.logOut} />
                                        </div>
                                    }
                                    <LoggedUser online={this.state.userOnline} firstName={firstName} lastName={lastName} role={role} campus={campus} toggleUserMenu={this.toggleUserMenu} closeUserMenu={this.closeUserMenu} />
                                </div>
                            </div>
                        </Toolbar>
                        <div id="user-menu-container" style={{ display: 'flex', marginLeft: "81vw" }}>
                            {this.state.openMenu && isSuperadmin &&
                                <SuperadminMenu
                                    version={`v${packageJson.version}`}
                                    campusFilter={campusId}
                                    campusSwitch={(newAccount) => this.campusSwitch(newAccount)}
                                    logout={this.logOut}
                                    goToAccessAdmin={this.goToAccessAdmin}
                                />
                            }
                        </div>
                        {this.renderMainContent()}
                        <React.Fragment>
                            {!!this.props.acl && !!this.props.acl.views && this.props.acl.views[1].access &&
                                <div style={{ display: this.shouldRenderMap }}>
                                    <MapContainer />
                                </div>
                            }
                        </React.Fragment>
                        {!!this.props.acl && !!this.props.acl.views &&
                            <React.Fragment>
                                {(this.props.viewProps === HISTORY || this.props.viewProps === WORKFLOW_AUTOMATION) && this.props.currentView === MAP ?
                                    <HistoryInfo handleOpenSnackBar={this.handleOpenSnackBar} />
                                    :
                                    <VerticalTabs
                                        featureFlags={this.props.featureFlags}
                                        openWayfinding={this.openWayfinding}
                                        handleToggle={this.handleToggle}
                                        setCurrentMenuVal={this.setCurrentMenuVal} />
                                }
                            </React.Fragment>
                        }
                        {!!this.props.acl && !!this.props.acl.views && this.props.acl.views[6].access &&
                            <Chat />
                        }
                        <MasterAlert
                            snackStyle={{ marginTop: '5vh' }}
                            anchor={{ vertical: 'top', horizontal: 'right' }}
                            openSnack={snackBarOpen}
                            handleClose={this.handleCloseSnackBar}
                            alertStyle={{ backgroundColor: '#2843A3', borderBottom: '7px solid #B5C5FF' }}
                            elevation={6}
                            variant={"filled"}
                            type={'success'}
                            message={this.snackBarMsg}
                        />
                    </React.Fragment>
                }
            </div>
        );
    }
}

Dashboard.propTypes = {
    access_token: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    logoutPromise: PropTypes.func.isRequired,
    listUsers: PropTypes.func.isRequired,
    getACL: PropTypes.func.isRequired,
    setTokenUserId: PropTypes.func.isRequired,
    acl: PropTypes.object.isRequired,
    changeView: PropTypes.func.isRequired,
    resetNewRoute: PropTypes.func.isRequired,
    allCallouts: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    //currentMenuValue: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
    access_token: state.auth.token,
    userId: state.auth.userId,
    users: state.user.users,
    acl: state.auth.acl,
    acls: state.acl.acls,
    currentView: state.views.currentView,
    open: state.views.drawerOpen,
    isDefaultViewLoaded: state.views.isDefaultViewLoaded,
    userInfo: state.auth.userInfo,
    viewProps: state.views.viewProps,
    contacts: state.contactUserList.usersList,
    directContactsforAUser: state.contactEvent.directContacts,
    userSelected: state.contact.userSelected,
    nameList: state.contact.names,
    peopleMapFilter: state.views.peopleMapFilter,
    equipmentMapFilter: state.views.equipmentMapFilter,
    currentMenuValue: state.views.currentMenuValue,
    transporterFound: state.views.transporterFound,
    contactsForUser: state.contact.directContacts,
    campusInfo: state.auth.userInfo.campusInfo,
    socketChat: state.auth.socketChat,
    user: state.superAdmin.user,
    expireSession: state.auth.expireSession,
    unreadBroadcast: state.views.unreadBroadcast,
    featureFlags: state.auth.featureFlags,
    mapConfigs: state.views.mapConfigs,
})

export default connect(mapStateToProps, {
    setFeatureFlags,
    logoutPromise,
    listUsers,
    getACL,
    setTokenUserId,
    campusLogin,
    createLog,
    changeView,
    resetNewRoute,
    changeDrawer,
    validateUserName,
    allContacts,
    fetchACLS,
    filterUsers,
    changePeopleFilter,
    changeEquipmentFilter,
    setCurrentMenuValue,
    openBroadcastChat,
    unreadBroadcastChat,
    changeZoomToEquipment,
    updateUserInfo,
    allUsers,
    getSuperAdmins,
    getCampuses,
    adminACLS,
    sendLoginAdminParams,
    expireSession,
    setMapConfigurations
})(withRouter(Dashboard));
