import _ from 'lodash';
import moment from 'moment';

/**
* Function that handles User job history traces to show them on map
*/
export const  renderUserJobHistory = ( renderUserJobHistoryParams, renderUserJobHistoryActions ) => {
    const {handleFilterUsersByFloor, storeHistoryTraces, updateHistoryRoutesCleanList, changeGeoJSONJobs} = renderUserJobHistoryActions;
    const {spaghetti, historyRoute, timeWindow, wifi, jobInfo, jobsToHide, activeFloor, centerHistoryMap, animationShowTrace, animationShowCallouts, mapkit, historyRoutesCleanList, openHTFirstTime} = renderUserJobHistoryParams;

    let currentFloor = 0;
    let geoJSONJob = {};
    const {geolocation} = historyRoute[0];
    currentFloor = handleFilterUsersByFloor();
    mapkit.cleanTraceOverlays();
    mapkit.handleAnnotationsHistory();

    if(timeWindow.length>0){
        let historyRouteTimeWindow = handleTracesWithTimeWindow({timeWindow, jobsToHide, historyRoute, spaghetti, historyRoutesCleanList}, updateHistoryRoutesCleanList);
        storeHistoryTraces(historyRouteTimeWindow);
        if(timeWindow[2] === 'slider')
          geoJSONJob= mapkit.handleTraces(historyRouteTimeWindow,wifi,geolocation,jobInfo);
        if(timeWindow[2] === 'animation')
          geoJSONJob= mapkit.handleTracesAnimation(historyRouteTimeWindow,wifi,geolocation,jobInfo, animationShowTrace, animationShowCallouts);
    }else{
        if(jobsToHide.length>0){
            cleanJobList(jobsToHide, historyRoute, updateHistoryRoutesCleanList);
            storeHistoryTraces(historyRoutesCleanList);
            geoJSONJob= mapkit.handleTraces(historyRoutesCleanList,wifi,geolocation,jobInfo);
        }else{
            storeHistoryTraces(historyRoute);
            geoJSONJob= mapkit.handleTraces(historyRoute,wifi,geolocation,jobInfo);
        }
    }

    mapkit.handleFloorTraces(activeFloor,currentFloor);
    // mapkit.handleSetMapDefault();
    let centerMapHidden = checkJobsToHideList(jobsToHide, centerHistoryMap);
    changeGeoJSONJobs(geoJSONJob);
    if(!_.isEmpty(centerHistoryMap) && !centerMapHidden && timeWindow[2] !== 'animation') {
        mapkit.handleCenterHistoryTraceAnnotation(centerHistoryMap,jobInfo);
    }

    //'Open' trace in start point by default
    if(!!openHTFirstTime){
        mapkit.handleCenterHistoryTraceAnnotation({type: "startTag", jobId: jobInfo[0].job},jobInfo);
    }

    return mapkit;
}

const checkJobsToHideList=(jobsToHide, centerHistoryMap)=>{
    let centerMapHidden = false;
    if(jobsToHide.length>0){
        jobsToHide.forEach(job=>{
            if(job === centerHistoryMap.jobId) {
                centerMapHidden= true;
            }
        });
    }
    return centerMapHidden;
}

/**
* Function that filters traces based on timewindow
*/
const handleTracesWithTimeWindow=({timeWindow, jobsToHide, historyRoute, spaghetti, historyRoutesCleanList}, updateHistoryRoutesCleanList)=>{
    let historyRouteWindow = [];
    let startDate = moment(moment(timeWindow[0]).toISOString());
    let auxEndDate = moment(moment(timeWindow[1]).toISOString());
    let endDate = auxEndDate.add(1, 'seconds');
    if(jobsToHide.length>0){
        cleanJobList(jobsToHide, historyRoute, updateHistoryRoutesCleanList);
        historyRoutesCleanList.map(trace=>{
            let date = moment(trace.create_date);
            if(date >= startDate && date<= endDate) {
                historyRouteWindow.push(trace);
            }
                
            return null;
        });
    }else{
        if (!!historyRoute) {
            historyRoute.forEach(trace=>{
                let date = moment(trace.create_date);
                if(date >= startDate && date<= endDate){
                    historyRouteWindow.push(trace);
                }
            });
        }
        else {
            spaghetti.forEach(trace=>{
                let date = moment(trace.create_date);
                if(date >= startDate && date<= endDate){
                    historyRouteWindow.push(trace);
                }
            });
        }
    }
    return historyRouteWindow;
}

const cleanJobList=(jobsToHide, historyRoute, updateHistoryRoutesCleanList)=>{
    let jobList = historyRoute;
    let auxJobList = [];
    jobsToHide.forEach(job=>{
        jobList.forEach(trace=>{
            if(trace.jobId!==job) {
                auxJobList.push(trace);
            }
        });
        jobList = auxJobList;
        auxJobList = [];
    });

    updateHistoryRoutesCleanList(jobList)
}