import {LIST_CAMPUSES, UPDATE_CAMPUS, CREATE_CAMPUS, UPLOAD_LEVEL, UPLOAD_OPENING, UPLOAD_UNIT, UPLOAD_VENUE, GET_CAMPUS} from './types';
import axios from 'axios';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();

export const getCampus = (campusId)=>(dispatch, getState) =>{
  return new Promise ((resolve, reject) => {
    try{
      axios.get(`${secureUrl.hostUrl}/api/campuses/${campusId}?access_token=${getState().auth.token}`)
      .then(resp=>{
        if (!!resp.data) {
          dispatch({
            type: GET_CAMPUS,
            payload: resp.data,
            campusId: campusId
          });
          resolve(true);
        }
      })
    }catch(error){
      console.error("[CAMPUS ACTIONS] An error ocurred in getCampus: ", error);
      reject(error);
    }
  });
};

export const getCampuses = ()=>(dispatch, getState) =>{
  return new Promise ((resolve, reject) => {
    try{
      axios.get(`${secureUrl.hostUrl}/api/campuses?filter={"where":{"disabled":false}}&access_token=` + getState().auth.token
      ).then(resp=>{
        if (resp.data.length > 0) {
          dispatch({
            type: LIST_CAMPUSES,
            payload: resp.data
          });
          resolve(true);
        }
      })
    }catch(error){
      console.error("[CAMPUS ACTIONS] An error ocurred in getCampuses: ", error);
      reject(error);
    }
  });
};

export const createCampus = (data) => (dispatch, getState)=>{
  return new Promise ((resolve, reject)=>{
    try{
      axios.post(`${secureUrl.hostUrl}/api/campuses?access_token=${getState().auth.token}`, data
      ).then((resp)=>{
        dispatch({
          type: CREATE_CAMPUS,
          payload: true
        });
        resolve(true);
      })
    }catch(error){
      console.error("[CAMPUS ACTIONS] An error ocurred in createCampus: ", error);
      reject(error);
    }
  });
};

export const updateCampus = (campusId, data)=>(dispatch, getState)=> {
  return new Promise ((resolve, reject)=>{
    try{
      axios.put(`${secureUrl.hostUrl}/api/campuses/${campusId}`, data
      ).then((resp)=>{
        dispatch({
          type: UPDATE_CAMPUS,
          payload: true
        });
        resolve(true);
      })
    }catch(error){
      console.error("[CAMPUS ACTIONS] An error ocurred in updateCampus:", error);
      reject(error);
    }
  });
};

export const uploadIMDF = (campusId, file, fileName)=>(dispatch, getState)=>{
  return new Promise (async(resolve, reject)=>{
    try{
      const IMDF ={
        unit:{
          label: 'units',
          msg: 'Unit',
          action: UPLOAD_UNIT
        },
        venue:{
          label: 'venues',
          msg: 'Venue',
          action: UPLOAD_VENUE
        },
        level:{
          label: 'levels',
          msg: 'Level',
          action: UPLOAD_LEVEL
        },
        opening:{
          label: 'openings',
          msg: 'Opening',
          action: UPLOAD_OPENING
        }
      };
      let readFile = async(e)=>{
        let name = null;
        let type = null;
        let campus = null;
        let features = [];
        let fileRead = JSON.parse(e.target.result);
  
        name = fileRead['name'];
        type =  fileRead['type'];
        campus = fileRead['campusId'];
        let featuresArray = fileRead['features'];
        featuresArray.forEach(obj=>{
          features.push(obj);
        });
        let chunkLength = Math.round(features.length / 2);
        let slicesToSend = [];
        let copy = features;
        while(copy.length > 0){
          let arrayChunk = copy.splice(0,chunkLength);
          slicesToSend.push(arrayChunk);
        }
        let data = {
          name: name,
          type: type,
          campusId: campus,
          features: slicesToSend[0]
        };
        await axios({
          url:`${secureUrl.hostUrl}/api/${IMDF[fileName].label}/upsertWithWhere?where={"campusId":"${campusId}"}&access_token=${getState().auth.token}`,
          method: 'post',
          data: data
        }).then(async res=> {
          if(res.status === 200){
              await axios.get(`${secureUrl.hostUrl}/api/${IMDF[fileName].label}?filter={"where":{"campusId":"${campusId}"}}&access_token=${getState().auth.token}`)
              .then(async res=> {
                if(res.status === 200){
                  const fileId = res.data[0].id;
                  let chunksUploaded = 1;
                  for (let index = 1; index < slicesToSend.length; index++) {
                    const featureName = `features${index+1}`;
                    let newData = {
                      [featureName]: slicesToSend[index]
                    };
                    await axios.patch(`${secureUrl.hostUrl}/api/${IMDF[fileName].label}/${fileId}?access_token=${getState().auth.token}`, newData)
                    .then(async res=>{
                      if(res.status===200){
                        chunksUploaded++;
                      }
                    }).catch(err=>{
                      console.error(`[CAMPUS ACTIONS] uploadIMDF, Error ${IMDF[fileName].msg} upload second chunk:`,err);
                      reject();
                    });
                  }
                  if(chunksUploaded === slicesToSend.length){
                    console.info(`[CAMPUS ACTIONS] uploadIMDF, ${IMDF[fileName].msg} successfully uploaded!`);
                    await axios.get(`${secureUrl.hostUrl}/api/${IMDF[fileName].label}/updateRedis?campusId=${campusId}&access_token=${getState().auth.token}`)
                    .then(res=> {
                      if(res.status===200){
                        console.info(`[CAMPUS ACTIONS] uploadIMDF, ${IMDF[fileName].msg} updated in Redis!`);
                        dispatch({
                          type: IMDF[fileName].action,
                          payload: true
                        });
                        resolve(true);
                      }else{
                        reject();
                      }
                    }).catch(err=>{
                      console.error(`[CAMPUS ACTIONS] uploadIMDF, Error ${IMDF[fileName].msg} update Redis:`, err);
                      reject();
                    });
                  }else{
                    reject();
                  }
                }else{
                  reject();
                }
              }).catch(err=>{
                console.error(`[CAMPUS ACTIONS] uploadIMDF, Error ${IMDF[fileName].msg} get file info:`, err);
                reject();
              })
          }else{
            reject();
          }
        }).catch(e=> {
          console.error(`[CAMPUS ACTIONS] uploadIMDF, Error ${IMDF[fileName].msg}:`, e);
          reject(e);
        });
      };
    
      let reader = new FileReader();
      reader.onload = readFile;
      reader.readAsText(file);

    }catch(error){
      console.error("[CAMPUS ACTIONS] An error ocurred in uploadIMDF:", error);
      reject(error);
    }
  });
};