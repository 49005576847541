import {
  groupLocationsByJob,
  handleTraceAnnotationCoords,
  getCurrentFloorColor,
  polyline_showWifi_filter,
  polylineJobTrace,
  setPolylineStyle,
  setPolylineCoords,
  checkLocationDotsInJob,
  createHistoryTraceAnnotationCallout,
  createHistoryTraceAnnotation
} from "./constants";

export const HANDLE_ANIMATION = (mapkit, traces, showWifi, geolocation, jobHistoryInfo, traceFilter, calloutFilter, showHistoryMap, historyTraceStore, mapConfigs)=>{
    let geoJSONJobs = {
      "type": "FeatureCollection",
      "features": []
    };
    if (traces.length > 0) {
      showHistoryMap = true;
      let showHistoryTrace = true;
      historyTraceStore = [];
      let polylineJob = [];
      let tracesAux =  traces.filter(trace=>trace.geolocation.latitude !== 0 && trace.geolocation.longitude !== 0) || [];
      const grouped = groupLocationsByJob(tracesAux);
      const jobs = Object.values(grouped);
  
      jobs.forEach((job, jobIndex)=>{
        let points = [];
        let lastItem = job.length - 1;
        let traceAnnotationCoords = handleTraceAnnotationCoords(job, lastItem);
  
        //? Check if TraceHealing is finished
        let currentJobInfo = jobHistoryInfo.find(hJob=>hJob.job === job[0].jobId);
        let THCompleted = currentJobInfo.traceHCompleted;
  
        points = checkLocationDotsInJob(job, points, showHistoryTrace);
        const historyTrace = createPolylineBasedOnDot(points, mapConfigs, showWifi, THCompleted,  polylineJob, traceFilter);
  
        if (showHistoryTrace) {
          //? Create Start/end annotations for each job
          mapkit = handleStart_EndAnnotations(mapkit, traceAnnotationCoords, currentJobInfo, mapConfigs, calloutFilter);
        }
        historyTraceStore.push(historyTrace);
        mapkit.addOverlays(historyTrace);
      });
      geoJSONJobs.features = polylineJob;
    }

    return [geoJSONJobs, mapkit, showHistoryMap, historyTraceStore];
};

const createPolylineBasedOnDot = (points, mapConfigs, showWifi, THCompleted,  polylineJob, traceFilter) =>{
  let floorColor = "";
  let traceStatus = "";
  let lineStyle = [];
  let ordinalF = 0;
  let historyTrace = [];
  let coordinatesPerJob = [];

  points.forEach(trace=>{
    floorColor = getCurrentFloorColor(mapConfigs, trace);
    ordinalF = trace[0][0];
    coordinatesPerJob = [];
    lineStyle = [];
    traceStatus = "normal";
    let newfloorColor = floorColor;
    let lastDot = trace[0][1][trace[0][1].length - 1];

    if (showWifi) {
      const polylineStyle = polyline_showWifi_filter(lastDot);
      newfloorColor = polylineStyle.newfloorColor;
      traceStatus = polylineStyle.traceStatus;
    }
    if (lastDot.missingTrace && !THCompleted) {
      newfloorColor = floorColor;
      lineStyle = [6, 3];
      traceStatus = "missing";
    }

    floorColor = newfloorColor;
    if (!traceFilter) {
      floorColor = "transparent";
    }
    const polylineCoords = setPolylineCoords(trace);
    let coords = polylineCoords.mapkitCoords;
    coordinatesPerJob = polylineCoords.coordinatesPerJob;

    if (coordinatesPerJob.length > 1) {
      polylineJob = polylineJobTrace(polylineJob, ordinalF, traceStatus, floorColor, coordinatesPerJob);
    }

    let style = setPolylineStyle(lineStyle, floorColor);
    let polyline = new window.mapkit.PolylineOverlay(coords, { style: style, data: {subtitle: "historyTrace", title: ordinalF} });
    historyTrace.push(polyline);
  });

  return historyTrace;
}

const handleStart_EndAnnotations = (mapkit, traceAnnotationCoords, currentJobInfo, mapConfigs, calloutFilter) =>{
  traceAnnotationCoords.forEach(tag=>{
    let tagCoords = new window.mapkit.Coordinate(tag[0].latitude, tag[0].longitude);
    let tagCoordsEnd = new window.mapkit.Coordinate(tag[1].latitude, tag[1].longitude);
    let TagOptions = {
      calloutEnabled: false,
      title: currentJobInfo.jobLabel,
      subtitle: "historyTrace",
      enabled: true,
      data: {'title': "startTag", 'floor': tag[0].floor}
    };
    let TagOptionsCallout = {
      calloutEnabled: false,
      title: currentJobInfo.jobLabel,
      subtitle: "historyTraceCallout",
      data: {'title': "startTag", 'floor': tag[0].floor, 'date': tag[0].date, 'label': 'Job Start'},
      enabled: true,
      visible: calloutFilter ? true : false
    };
    let TagOptionsEnd = {
      calloutEnabled: false,
      title: currentJobInfo.jobLabel,
      subtitle: "historyTrace",
      enabled: true,
      data: {'title': "endTag", 'floor': tag[1].floor}
    };
    let TagOptionsEndCallout = {
      calloutEnabled: false,
      title: currentJobInfo.jobLabel,
      subtitle: "historyTraceCallout",
      data: {'title': "endTag", 'floor': tag[1].floor, 'date': tag[1].date, 'label': 'Job End'},
      enabled: true,
      visible: calloutFilter ? true : false
    };
    mapkit = createHistoryTraceAnnotation(mapkit, tagCoords, TagOptions, mapConfigs);
    mapkit = createHistoryTraceAnnotation(mapkit, tagCoordsEnd, TagOptionsEnd , mapConfigs);
    mapkit = createHistoryTraceAnnotationCallout(mapkit, tagCoords, TagOptionsCallout, mapConfigs);
    mapkit = createHistoryTraceAnnotationCallout(mapkit, tagCoordsEnd, TagOptionsEndCallout, mapConfigs);
  });

  return mapkit;
}