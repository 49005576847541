const { PEOPLE_ANNOTATION_FILTERS } = require('../mapFilters/mapFilters');
const { USER_LOCATION_STATUS } = require("../../mapkitJS/IMDF.constants");
const { getFloorColorByOrdinal } = require("../../floorColors");

export const PEOPLE_ANNOTATION = (data, mapkit, showHistoryMap, showCalloutTitle, mapRotation, mapConfigs) => {
  const { location, trackUserId, trackAnnotationId, trackType, showTraces, activeFloor, allCallouts, smallCallouts, indoorOnly } = data;
  [mapkit, showHistoryMap] = PEOPLE_ANNOTATION_FILTERS(trackType, showTraces, location, activeFloor, indoorOnly, mapkit, showHistoryMap, mapConfigs);

  if (typeof trackUserId === 'string' && trackType === 'transporterPhone' && location.id === trackAnnotationId) {
    mapkit = handleCheckMapAnnotations(mapkit);
    mapkit = handleMapConfigUserTracking(location, mapkit, mapRotation);
  }

  if (typeof trackUserId === 'string' && trackType === 'transporter' && location.user_id === trackUserId) {
    mapkit = handleCheckMapAnnotations(mapkit);
    mapkit = handleMapConfigUserTracking(location, mapkit, mapRotation);
  }

  let person = {
    coordinate: new window.mapkit.Coordinate(location.latitude, location.longitude),
    callOutCoordinate: new window.mapkit.Coordinate(location.latitude, location.longitude),
  };

  let TransporterOptions = {
    calloutEnabled: false,
    title: location.id,
    subtitle: "location",
    data: location,
    enabled: true,
  };

  let showSingleCallout = false;
  let showCallout = true;
  if (showCallout && showCalloutTitle === person.title)
    showSingleCallout = true;

  let showSmallCallout = false;
  if (allCallouts && smallCallouts) {
    showCallout = false;
    showSmallCallout = true;
  }
  if (!allCallouts && !smallCallouts) {
    showCallout = false;
    showSmallCallout = false;
  }

  let calloutOptions = {
    calloutEnabled: false,
    title: location.id,
    subtitle: "callout",
    data: location,
    enabled: true,
    visible: showCallout
    //&& (allCallouts || showSingleCallout)? true:false
  };

  let smallCalloutOptions = {
    calloutEnabled: false,
    title: location.id,
    subtitle: "smallCallout",
    data: location,
    enabled: true,
    visible: showSmallCallout
  };

  if (activeFloor[0] === true) {
    if (activeFloor[1] === location.floor)
      mapkit = handleCreateLocationCallouts(mapkit, person, TransporterOptions, smallCalloutOptions, calloutOptions, mapConfigs);
  } else if (indoorOnly) {
    if (location.floor !== null && location.floor !== undefined && location.floor !== 'outside' && location.floor !== -3)
      mapkit = handleCreateLocationCallouts(mapkit, person, TransporterOptions, smallCalloutOptions, calloutOptions, mapConfigs);
  } else if (indoorOnly && activeFloor[0] === true) {
    if (location.floor !== null && location.floor !== undefined && location.floor !== 'outside' && location.floor !== -3 && activeFloor[1] === location.floor)
      mapkit = handleCreateLocationCallouts(mapkit, person, TransporterOptions, smallCalloutOptions, calloutOptions, mapConfigs);
  } else {
    mapkit = handleCreateLocationCallouts(mapkit, person, TransporterOptions, smallCalloutOptions, calloutOptions, mapConfigs);
  }

  return [mapkit, showHistoryMap, showCalloutTitle];
};

const handleCreateLocationCallouts = (map, person, TransporterOptions, smallCalloutOptions, calloutOptions, mapConfigs) => {
  map = createLocationAnnotation(person, TransporterOptions, map, mapConfigs);
  map = createSmallCalloutAnnotation(person, smallCalloutOptions, map, mapConfigs);
  map = createCalloutAnnotation(person, calloutOptions, map, mapConfigs);
  return map;
};

/**
 * Set the map annotations (labels) visible
*/
const handleCheckMapAnnotations = (mapkit) => {
  mapkit.annotations.map(res => {
    if (res.subtitle === "map annotation")
      res.visible = true;
  });
  return mapkit;
};

/**
* Reconfigures the properties of map in order to 'follow' the user to track
*/
const handleMapConfigUserTracking = (location, mapkit, mapRotation) => {
  mapkit.center = new window.mapkit.Coordinate(location.latitude, location.longitude);
  mapkit.span = new window.mapkit.CoordinateSpan(0.0004, 0.0004);
  mapkit.region = new window.mapkit.CoordinateRegion(mapkit.center, mapkit.span);
  mapkit.rotation = mapRotation;

  return mapkit;
};

const createLocationAnnotation = (person, TransporterOptions, mapkit, mapConfigs) => {
  let TransporterFactory = handleFactoryAnnotationTransporter(person.coordinate, TransporterOptions, mapConfigs);
  var transporter = new window.mapkit.Annotation(person.coordinate, TransporterFactory, TransporterOptions);
  mapkit.addAnnotation(transporter);
  return mapkit;
};

const createCalloutAnnotation = (person, calloutOptions, mapkit, mapConfigs) => {
  let calloutFactory = handleFactoryAnnotationCallout(person.callOutCoordinate, calloutOptions, mapConfigs);
  var callout = new window.mapkit.Annotation(person.callOutCoordinate, calloutFactory, calloutOptions);
  mapkit.addAnnotation(callout);
  return mapkit;
};

const createSmallCalloutAnnotation = (person, calloutOptions, mapkit, mapConfigs) => {
  let calloutFactory = handleFactoryAnnotationSmallCallout(person.callOutCoordinate, calloutOptions, mapConfigs);
  var callout = new window.mapkit.Annotation(person.callOutCoordinate, calloutFactory, calloutOptions);
  mapkit.addAnnotation(callout);
  return mapkit;
};

const handleFactoryAnnotationTransporter = (coordinate, options, mapConfigs) => {
  return (coordinate, options) => {
    let divContainer = document.createElement("div");
    let divPin = document.createElement("div");
    let annotationColor = Object.keys(mapConfigs).length ? mapConfigs.users.location_Annotation.inactive : {};

    if (options.data.geolocation_status === USER_LOCATION_STATUS.offline) {
      annotationColor = Object.keys(mapConfigs).length ? mapConfigs.users.location_Annotation.offline : {};
    }
    if (options.data.geolocation_status === USER_LOCATION_STATUS.online) {
      annotationColor = Object.keys(mapConfigs).length ? mapConfigs.users.location_Annotation.active : {};
    }

    let styleProperties = Object.keys(annotationColor);
    styleProperties.forEach(property => {
      divPin.style[property] = annotationColor[property];
    });

    divContainer.append(divPin);
    return divContainer;
  };
};

const handleFactoryAnnotationCallout = (coordinate, options, mapConfigs) => {
  return (coordinate, options) => {
    let floorName = "GPS";
    let floorColor = "#757575";
    let nameLblSize = "10px";
    let fullName = options.data.firstName + " " + options.data.lastName;
    if (fullName > 16) nameLblSize = "9px";

    if (!!mapConfigs.floors) {
      mapConfigs.floors.map(floor => {
        if (floor.ordinal === options.data.floor) {
          floorName = floor.callout_name;
          //floorColor = floor.color;
          floorColor = getFloorColorByOrdinal(floor.ordinal);
        }
      });
    }

    const userName = fullName.replace(/&nbsp;/g, '');

    let divCallout = document.createElement("div"), id = "marker";
    divCallout.className = "user-callout";
    let calloutStyles = Object.keys(mapConfigs).length ? mapConfigs.users.location_Callout.main_Container : {};
    let calloutProperties = Object.keys(calloutStyles);
    calloutProperties.forEach(property => {
      divCallout.style[property] = calloutStyles[property];
    });

    let divDate = document.createElement("div");
    let dateStyles = Object.keys(mapConfigs).length ? mapConfigs.users.location_Callout.date_Container : {};
    let dateProperties = Object.keys(dateStyles);
    dateProperties.forEach(property => {
      divDate.style[property] = dateStyles[property];
    });
    divDate.append(options.data.calloutDate);

    let divPointer = document.createElement("div");
    let pointerCalloutStyles = Object.keys(mapConfigs).length ? mapConfigs.users.pointer : {};
    let pointerCalloutProperties = Object.keys(pointerCalloutStyles);
    pointerCalloutProperties.forEach(property => {
      divPointer.style[property] = pointerCalloutStyles[property];
    });

    divPointer.style.textOverflow = 'ellipsis';
    divPointer.style.overflow = 'hidden';
    divPointer.style.width = '110px';
    divPointer.style.whiteSpace = 'nowrap';

    if (options.data.trackDevice) {
      divPointer.style.fontSize = "12px";
      divPointer.append(options.data.assetTag);
    } else {
      divPointer.style.fontSize = nameLblSize;
      divPointer.append(userName);
    }
    divPointer.append(divDate);
    divCallout.append(divPointer);

    let divLevel = document.createElement("div");
    let floorDivStyles = Object.keys(mapConfigs).length ? mapConfigs.users.location_Callout.floor_Container : {};
    let floorDivProperties = Object.keys(floorDivStyles);
    floorDivProperties.forEach(property => {
      divLevel.style[property] = floorDivStyles[property];
    });
    divLevel.style.backgroundColor = floorColor;

    divLevel.append(floorName);
    divCallout.append(divLevel);

    return divCallout;
  };
};

const handleFactoryAnnotationSmallCallout = (coordinate, options, mapConfigs) => {
  return (coordinate, options) => {
    let floorColor = "#757575";
    let nameLblSize = "12px";

    if (!!mapConfigs.floors) {
      mapConfigs.floors.map(floor => {
        if (floor.ordinal === options.data.floor)
          //floorColor = floor.color;
          floorColor = getFloorColorByOrdinal(floor.ordinal);
      });
    }
    
    const firstName = options.data.firstName.trim();
    const lastName = options.data.lastName.trim();
    let initials = firstName.substring(0, 1) + lastName.substring(0, 1);

    let divCallout = document.createElement("div"), id = "marker";
    divCallout.className = "user-small-callout";
    let smallCalloutStyles = Object.keys(mapConfigs).length ? mapConfigs.users.small_location_Callout.main_Container : {};
    let smallCalloutProperties = Object.keys(smallCalloutStyles);
    smallCalloutProperties.forEach(property => {
      divCallout.style[property] = smallCalloutStyles[property];
    });
    divCallout.style.backgroundColor = floorColor;

    let divPointer = document.createElement("div");
    let pointerCalloutStyles = Object.keys(mapConfigs).length ? mapConfigs.users.pointer : {};
    let pointerCalloutProperties = Object.keys(pointerCalloutStyles);
    pointerCalloutProperties.forEach(property => {
      divPointer.style[property] = pointerCalloutStyles[property];
    });
    divPointer.style.fontSize = nameLblSize;
    divPointer.append(initials);
    divCallout.append(divPointer);

    return divCallout;
  };
};