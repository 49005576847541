import axios from "axios";
import { LIST_USERS } from './types';
import hostName from '../../components/utilities/hostName';

const secureUrl = new hostName();
secureUrl.initHostName();
export const filterUsers = () => (dispatch, getState) => {
  try{
    axios.get(`${secureUrl.hostUrl}/api/contact_tables`)
    .then((resp) => {
      dispatch({
        type: LIST_USERS,
        payload: resp.data,
      });
    });
  }catch(error){
    console.error("[CONTACT USER LIST ACTIONS] An error ocurred in filterUsers: ", error);
  }

};