/*
See LICENSE folder for this sample’s licensing information.
Abstract:
Main script responsible for creating the map.
*/
import  './style.css';
import * as CONSTS from './IMDF.constants';
import axios from 'axios';
import hostName from '../../utilities/hostName';
import { getFloorColorByOrdinal } from '../floorColors';

const {EQUIPMENT_FILTERS, PEOPLE_FILTERS, EQUIPMENT_ANNOTATION_FILTERS, TRACE_FILTERS} = require('../utils/mapFilters/mapFilters');
const {HANDLE_TRACES} = require('../utils/history/historyMap');
const {HANDLE_ANIMATION} = require('../utils/history/historyAnimation');
const {LOAD_IMDF}= require('../utils/mapLoader');
const {EQUIPMENT_ANNOTATION} = require('../utils/annotations/equipmentAnnotations');
const {PEOPLE_ANNOTATION} = require('../utils/annotations/peopleAnnotations');
const {handlePaintAnchorLocationsOnMap} = require('../utils/annotations/anchorAnnotations');
const {ERROR_TASKS} = require('./IMDF.constants');
const secureUrl = new hostName();
secureUrl.initHostName();

let showCallout = false;
let showCalloutTitle = "";
let historyTraceStore = [];
let ordinalFloor = -3;
let currentFloorActive= false;
let showHistoryMap= true;
let currentHistoryMapConfig = {
    center: [],
    span: [],
    rotation:0,
    jobId: ''
}
let mapAttributes ={};

export default class indoorMap{
      constructor(config, mapConfigs){
        this.mapConfigs = mapConfigs;
        const span = new window.mapkit.CoordinateSpan(CONSTS.ZOOM_INFO[config.zoom_level].lat, CONSTS.ZOOM_INFO[config.zoom_level].lon);
        const center = new window.mapkit.Coordinate(config.lat,config.long);
        const region = new window.mapkit.CoordinateRegion(center, span);
                this.attributes={
                    region:region,
                    rotation:config.rotation,
                    showsPointsOfInterest: false,
                    showsMapTypeControl: false
                };
                mapAttributes= this.attributes;
        this.map = new window.mapkit.Map(CONSTS.MAP,this.attributes);
        this.map.showsCompass = window.mapkit.FeatureVisibility.Visible;
        this.map.showsZoomControl = true;

        this.map.addEventListener(CONSTS.MAP_EVENTS.configChange.type, function(event){
            switch (event.status) {
                case CONSTS.MAP_EVENTS.configChange.status.initialized:
                    console.info("[INDOORMAP] configuration change, initialized: ",CONSTS.MAP_EVENTS.configChange.logs.initialized)
                    break;
                case CONSTS.MAP_EVENTS.configChange.status.refreshed:
                    console.info("[INDOORMAP] configuration change, refreshed: ",CONSTS.MAP_EVENTS.configChange.logs.refreshed)
                    break;
                default:
                    break;
            }
        });

        this.map.addEventListener(CONSTS.MAP_EVENTS.error, function(event){
            switch (event.status) {
                case CONSTS.MAP_EVENTS.error.status.unauthorized:
                    console.error("[INDOORMAP] An error ocurred, unauthorized: ",CONSTS.MAP_EVENTS.error.logs.unauthorized)
                    break;
                case CONSTS.MAP_EVENTS.error.status.manyRequests:
                    console.error("[INDOORMAP] An error ocurred, manyRequests: ",CONSTS.MAP_EVENTS.error.logs.manyRequests)
                    break;
                default:
                    break;
            }
        });
// Map Listeners
    this.map.addEventListener("select", function(event) {
            if(!!event.annotation && event.annotation.subtitle === CONSTS.ANNOTATION_SUBTITLES.historyTrace){
                event.target.annotations.map(res=>{
                    if(res.title === event.annotation.title && res.data.title === event.annotation.data.title
                    && res.subtitle===CONSTS.ANNOTATION_SUBTITLES.historyTraceCallout) {
                        res.visible = !res.visible;
                    }
                    return null;
                });
            }
            if(!!event.annotation && event.annotation.subtitle === CONSTS.ANNOTATION_SUBTITLES.location){
                event.target.annotations.map(res=>{
                    if(res.title === event.annotation.title && res.subtitle===CONSTS.ANNOTATION_SUBTITLES.callout){
                        if(res.visible){
                            showCallout = false;
                            showCalloutTitle = ""
                        }else{
                            res.visible = !res.visible;
                            showCallout = true;
                            showCalloutTitle = res.title;
                        }
                    }

                    return null;
                });
            }
            if(!!event.annotation && event.annotation.subtitle ===CONSTS.ANNOTATION_SUBTITLES.equipmentLocBubble){
                event.target.annotations.map(res=>{
                    if(res.title === event.annotation.title && res.subtitle===CONSTS.ANNOTATION_SUBTITLES.equipmentCallout) {
                        if(!res.visible){
                            res.visible = !res.visible;
                            showCallout = true;
                            showCalloutTitle = res.title;
                            event.annotation.visible = false;
                        }
                    }

                  return null;
                });
            }
            if(!!event.annotation && event.annotation.subtitle ===CONSTS.ANNOTATION_SUBTITLES.equipmentCallout){
                event.target.annotations.map(res=>{
                    if(res.title === event.annotation.title && res.subtitle===CONSTS.ANNOTATION_SUBTITLES.equipmentLocBubble){
                        if(!res.visible){
                            showCallout = false;
                            showCalloutTitle = "";
                            event.annotation.visible =false;
                            res.visible= !res.visible;
                        }
                    }

                    return null;
                });
            }
            if(!!event && !!event.annotation && (event.annotation.subtitle ===CONSTS.ANNOTATION_SUBTITLES.callout || event.annotation.subtitle ===CONSTS.ANNOTATION_SUBTITLES.location) ){
                event.target.center = new window.mapkit.Coordinate(event.annotation.coordinate.latitude,event.annotation.coordinate.longitude);
                event.target.span= new window.mapkit.CoordinateSpan(0.0004, 0.0004);
                event.target.region= new window.mapkit.CoordinateRegion(event.target.center, event.target.span);
                event.target.rotation= mapAttributes.rotation;
            }
    });
    this.map.addEventListener("region-change-end", function(event){
        if(showHistoryMap){
            currentHistoryMapConfig.center[0]= event.target.region.center.latitude;
            currentHistoryMapConfig.center[1]= event.target.region.center.longitude;
            currentHistoryMapConfig.span[0]= event.target.region.span.latitudeDelta;
            currentHistoryMapConfig.span[1]= event.target.region.span.longitudeDelta;
            currentHistoryMapConfig.rotation= event.target.rotation;
          }
    });
    this.map.addEventListener("zoom-end", function(event){
          if(event.target.region.span.latitudeDelta>CONSTS.MAP_EVENTS.zoomEnd.zoomMax){
                event.target.annotations.map(res=>{
                  if(res.subtitle === CONSTS.ANNOTATION_SUBTITLES.mapAnnotation){
                    res.visible = false;
                  }
                  return null;
                });
          }

            if(event.target.region.span.latitudeDelta<=CONSTS.MAP_EVENTS.zoomEnd.zoomMin){
                event.target.annotations.map(res=>{
                  if(res.subtitle === CONSTS.ANNOTATION_SUBTITLES.mapAnnotation) {
                    res.visible = true;
                  }
                  return null;
                    
                });
          }
          if(showHistoryMap){
            currentHistoryMapConfig.center[0]= event.target.region.center.latitude;
            currentHistoryMapConfig.center[1]= event.target.region.center.longitude;
            currentHistoryMapConfig.span[0]= event.target.region.span.latitudeDelta;
            currentHistoryMapConfig.span[1]= event.target.region.span.longitudeDelta;
            currentHistoryMapConfig.rotation= event.target.rotation;
          }
      });
    };

    handleMKInit(MKToken){
        window.mapkit.init({
            authorizationCallback: function(done) {
                done(MKToken);
            }
        });
    }

    handleUpdateConfigMap = (token, campusInfo)=>{
        //Remove floor switch
        var element = document.getElementById(CONSTS.LEVELPICKER);
        if (!!element.parentNode) {
            element.parentNode.removeChild(element);
        }
        //Remove old map annotations
        this.map.annotations.map(res=>{
            if(res.subtitle===CONSTS.ANNOTATION_SUBTITLES.mapAnnotation) {
                this.map.removeAnnotation(res);
            }
            return null;
        });
        //Remove old map overlays
        this.map.overlays.map(res=>{
            this.map.removeOverlay(res);
            return null;
        });
        const span = new window.mapkit.CoordinateSpan(CONSTS.ZOOM_INFO[campusInfo.config.zoom_level].lat, CONSTS.ZOOM_INFO[campusInfo.config.zoom_level].lon);
        const center = new window.mapkit.Coordinate(campusInfo.config.lat,campusInfo.config.long);
        const region = new window.mapkit.CoordinateRegion(center, span);
        this.map.region = region;
        this.attributes.region = region;
        this.map.rotation = this.attributes.rotation;
        this.defaultFloor = campusInfo.config.default_floor;
        this.token = token;
        this.campusId = campusInfo.id;
        this.loadImdfArchive(this);
    }

    handleConfigMap = (floor,token,campusId)=>{
        this.defaultFloor=floor;
        this.token=token;
        this.campusId=campusId;
        this.loadImdfArchive(this);
    }

    handleSetMapDefault=()=>{
        const region = this.attributes.region;
        this.map.region=region;
        this.map.rotation= this.attributes.rotation;
    }

    checkMapZoom=()=>{
        this.handleSetMapDefault();
        this.handleHideMapAnnotations();
    }

    /**
    * Function that loads map from IMDF file
    */
    loadImdfArchive=({map,attributes,defaultFloor,token,campusId})=>{
      let mapConfigs = this.mapConfigs;
      [ordinalFloor, currentFloorActive, historyTraceStore, showHistoryMap, this.map] = LOAD_IMDF({map,attributes, defaultFloor, token, campusId, ordinalFloor, currentFloorActive, historyTraceStore, showHistoryMap, mapConfigs});
    }

    handleRemoveUserLocationMark=(user)=>{
        if(user!==undefined){
            this.map.annotations.map(res=>{
                if (res.title===user) {
                    this.map.removeAnnotation(res);
                }
                return null;
            });
        }
    }

    handleCenterHistoryTraceAnnotation=(centerInfo,jobInfo)=>{
        let jobSelectedInfo = jobInfo.find(job=> job.job === centerInfo.jobId) || {};
        let coords = [];
        this.map.annotations.map(res=>{
            if (res.subtitle===CONSTS.ANNOTATION_SUBTITLES.historyTrace && res.data.title===centerInfo.type && res.title===jobSelectedInfo.jobLabel){
                coords.push(res.coordinate.latitude,res.coordinate.longitude);
            }
            // if (res.data.title===centerInfo.type && res.subtitle==="historyTraceCallout"){
            //     res.visible = true;
            // }
            return null;
        });
        this.map.center=new window.mapkit.Coordinate(coords[0],coords[1]);
        this.map.span= new window.mapkit.CoordinateSpan(CONSTS.TRACK_USER_SPAN.lat, CONSTS.TRACK_USER_SPAN.lon);
        this.map.region= new window.mapkit.CoordinateRegion(this.map.center, this.map.span);
        this.map.rotation= this.attributes.rotation;
    }

      /**
     * Clears the configuration for HistoryMap
     */
    clearHistoryMapConfig=()=>{
        currentHistoryMapConfig.center=[];
        currentHistoryMapConfig.span=[];
        currentHistoryMapConfig.rotation=this.attributes.rotation;
        currentHistoryMapConfig.jobId='';
    }

    /**
     * Remove map annotations
     */
    handleCheckMapAnnotations=()=>{
        this.map.annotations.map(res=>{
            if(res.subtitle===CONSTS.ANNOTATION_SUBTITLES.mapAnnotation) {
                res.visible = true;
            }
            return null;
        });
    }

    /**
     * Set the map annotations (labels) invisible
     */
    handleHideMapAnnotations=()=>{
        this.map.annotations.map(res=>{
            if(res.subtitle===CONSTS.ANNOTATION_SUBTITLES.mapAnnotation) {
                res.visible = false;
            }
            return null;
        });
    }

    /**
     * Removes all location annotations from map (location, callout and smallCallout)
     */
     removeAllLocationTypeAnnotations=()=>{
        this.map.annotations.map(dot=>{
            if(dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.callout || dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.smallCallout || dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.location) {
                this.map.removeAnnotation(dot);
            }
            return null;
        });
    }

    /**
     * Removes location annotations from map (callout and smallCallout)
     */
     removeAllLocationCalloutAnnotations=()=>{
            this.map.annotations.map(dot=>{
                if(dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.callout || dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.smallCallout) {
                    this.map.removeAnnotation(dot);
                }
                return null;
        });
    }

     /**
     * Removes historyTraces and polyline traces from map
     */
    cleanTraceOverlays=()=>{
        showHistoryMap=false;
        this.map.overlays.map(overlay=>{
            if(overlay.data[0]===CONSTS.OVERLAY_TYPES.polylineTraceFilter || overlay.data.subtitle===CONSTS.ANNOTATION_SUBTITLES.historyTrace) {
                this.map.removeOverlay(overlay);
            }
            return null;
        });
        this.cleanHistoryTags();
    }

    /**
     * Removes historyTraces of Offline users (if filter is active)
     */
    cleanTraceOverlaysOfflineUser=(Id)=>{
        this.map.overlays.map(overlay=>{
            if(overlay.data[0]===CONSTS.OVERLAY_TYPES.polylineTraceFilter && overlay.data[1]===Id) {
                this.map.removeOverlay(overlay);
            }
            return null;
        });
    }
    /**
     * Removes historyTraces start and end annotations
     */
    cleanHistoryTags=()=>{
        this.map.annotations.map(dot=>{
            if(dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.historyTrace || dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.historyTraceCallout) {
                this.map.removeAnnotation(dot);
            }
            return null;
        });
    }

    /**
     * Removes ALL equipment annotations from map
     */
    handleRemoveEquipmentAnnotations=()=>{
        this.map.annotations.map(dot=>{
            if(dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.equipmentLocBubble || dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.equipmentLocDot
                || dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.equipmentCallout) {
                    this.map.removeAnnotation(dot);
            }
            return null;
        });
    }

     /**
     * Removes equipment annotations by ASSET_TAG
     */
    handleRemoveEquipmentAnnotationByAssetTag=(asset_tag)=>{
        this.map.annotations.map(dot=>{
            if( dot.data.asset_tag === asset_tag &&
              (dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.equipmentLocBubble || dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.equipmentLocDot
                || dot.subtitle===CONSTS.ANNOTATION_SUBTITLES.equipmentCallout)){
                    this.map.removeAnnotation(dot);
            }
            return null;
                
        });
    }

    /**
    * Searches equipment annotation to zoom on it, and checks filters
    */
    handleZoomEquipmentGeolocation=(assetTag,activeFloor,indoorOnly,currentFloor,campusInfo)=>{
      this.map = EQUIPMENT_ANNOTATION_FILTERS(assetTag,activeFloor,indoorOnly,currentFloor,campusInfo, this.map, this.attributes.rotation);
    }

    handleAnnotationsHistory=()=>{
        this.map.annotations.map(dot=>{
            if(dot.visible && (dot.subtitle=== CONSTS.ANNOTATION_SUBTITLES.smallCallout || dot.subtitle=== CONSTS.ANNOTATION_SUBTITLES.callout
              || dot.subtitle=== CONSTS.ANNOTATION_SUBTITLES.location || dot.subtitle=== CONSTS.ANNOTATION_SUBTITLES.equipmentLocBubble
              || dot.subtitle=== CONSTS.ANNOTATION_SUBTITLES.equipmentLocDot || dot.subtitle=== CONSTS.ANNOTATION_SUBTITLES.equipmentCallout)){
                dot.visible = false;
              }
              return null;
              //this.map.removeAnnotation(dot);
        });
    }

     /**
     * Function that handles 'ShowTraces' filter
     */
    handleShowTracesFilter=({traces, activeUser, annotationId, indoorOnly, activeFloor, currentFloor, peopleMapFilter, viewProps, currentView})=>{
      this.map = TRACE_FILTERS(this.map, traces, activeUser, annotationId, indoorOnly, activeFloor, currentFloor, peopleMapFilter, viewProps, currentView);
    }

    /**
     * Renders a callout by equipment item
     */
    handleEquipmentGeolocation=(geolocationInfo,allCallouts,smallCallouts,indoorOnly,activeFloor,currentFloor)=>{
        let mapConfigs = this.mapConfigs;
        this.map = EQUIPMENT_ANNOTATION(geolocationInfo, allCallouts, smallCallouts, indoorOnly, activeFloor, currentFloor, this.map, mapConfigs);
    }

     /**
     * Main function that determines how to hide/remove annotations, and creates the new annotations
     */
    handleUserLocationMark=(locations)=>{
        locations.forEach(loc=>{
            if(!!loc.location){
                showHistoryMap= false;
                let mapConfigs = this.mapConfigs;
                [this.map, showHistoryMap, showCalloutTitle] = PEOPLE_ANNOTATION(loc, this.map, showHistoryMap, showCalloutTitle, this.attributes.rotation, mapConfigs);
            }else{
                console.info("[INDOORMAP] no information")
            }
        });
    }

    handleFactoryAnnotationTransporterTrace=(coordinate, options)=>{
        let mapConfigs = this.mapConfigs;
        
        return (coordinate, options) =>{
                let floorColor=""
                if (!!mapConfigs.floors) {
                    mapConfigs.floors.map(floor=>{
                        if(floor.ordinal === options.data.floor) {
                            //floorColor= floor.color;
                            floorColor = getFloorColorByOrdinal(floor.ordinal);
                        }
                        return null;
                    });
                }
                
                let divContainer=document.createElement("div")
                let divPin = document.createElement("div")
                divPin.className = "tr";
                divPin.classList.add("l"+options.data.floor);
                divPin.style.backgroundColor= floorColor;
                divPin.style.border= "2px solid "+floorColor;
                divContainer.append(divPin);
            return divContainer;
        }
    }

     /**
     * Reconfigures the properties of map in order to 'follow' the user to track
     */
    handleMapConfigUserTracking =(location)=>{
        this.map.center=new window.mapkit.Coordinate(location.latitude,location.longitude);
        this.map.span= new window.mapkit.CoordinateSpan(CONSTS.TRACK_USER_SPAN.lat, CONSTS.TRACK_USER_SPAN.lon);
        this.map.region= new window.mapkit.CoordinateRegion(this.map.center, this.map.span);
        this.map.rotation= this.attributes.rotation;
    }

    handleKeepTraceConditionalCurrentFloor =(res,currentFloor)=>{
        return res.subtitle === CONSTS.ANNOTATION_SUBTITLES.trace && res.data.floor!==currentFloor
    }

    handleRemoveTraces=(currentFloor)=>{
        //?Remove polylines
        this.map.overlays.map(overlay=>{
            if(overlay.data[0]===CONSTS.OVERLAY_TYPES.polylineTraceFilter
            && (overlay.data[2]!==currentFloor[1] || overlay.style.strokeColor=== CONSTS.GPS_COLOR)) {
                this.map.removeOverlay(overlay);
            }
            return null;
        });
    }

    /**
     * Function that removes all map annotations and callouts (except map labels)
     */
    handleRemoveAllAnnotations =()=>{
        this.map.annotations.map(res=>{
            if(res.subtitle!==CONSTS.ANNOTATION_SUBTITLES.mapAnnotation && res.visible){
                res.visible = false;
            }
            return null;
        });
    }

    /**
    * Function that show/hide equipment annotations depending of filters
    */
    handleMapEquipmentFilters=(handleMapEquipmentFiltersParams)=>{
        const {equipmentVisible, calloutVisible, smallVisible,filterFloor,floor,filterIndoor} = handleMapEquipmentFiltersParams;
        this.map.annotations = EQUIPMENT_FILTERS(this.map.annotations, equipmentVisible, calloutVisible, smallVisible,filterFloor,floor,filterIndoor);
    }

    /**
    * Function that show/hide people annotations depending of filters
    */
    handleMapFilters=(handleMapFiltersParams)=>{
        const {peopleVisible, calloutVisible, smallVisible,filterFloor,floor,filterIndoor,filterUser,user} = handleMapFiltersParams;
        this.map.annotations = PEOPLE_FILTERS(this.map.annotations, peopleVisible, calloutVisible, smallVisible,filterFloor,floor,filterIndoor,filterUser,user);
    }

    /**
    * Function that creates the history traces in map (using Polyline overlays)
    */
    handleTraces=(traces,showWifi,geolocation,jobHistoryInfo)=>{
       let geoJSON={};
       let mapConfigs = this.mapConfigs;
       [geoJSON, this.map, showHistoryMap, historyTraceStore]= HANDLE_TRACES(this.map,traces,showWifi,geolocation,jobHistoryInfo, showHistoryMap, historyTraceStore, mapConfigs);
       return geoJSON;
    }

   /**
   * Function that handles historyTraces when 'Current floor only' filter is active/inactive
   */
   handleFloorTraces=(floorActive, floor)=>{
       currentFloorActive = floorActive;
       if(floorActive){
            this.map.overlays.map(overlay=>{
                if(overlay.data.subtitle=== CONSTS.ANNOTATION_SUBTITLES.historyTrace && overlay.data.title!==floor){
                    overlay.visible = false;
                }
                return null;
            });
       }
   }

   handleTracesHistoryAnnotation=(trace,floor)=>{
    return (trace,floor) =>{
            let divPin = document.createElement("div");
                divPin.className = "trace-"+floor.title;
        return divPin;
        }
    }

    /**
    * Function that handles history traces to 'animate' them (using Polyline overlays)
    */
    handleTracesAnimation=(traces,showWifi,geolocation,jobHistoryInfo,traceFilter,calloutFilter)=>{
      let geoJSON={};
      let mapConfigs = this.mapConfigs;
      [geoJSON, this.map, showHistoryMap, historyTraceStore]= HANDLE_ANIMATION(this.map, traces, showWifi, geolocation, jobHistoryInfo, traceFilter, calloutFilter, showHistoryMap, historyTraceStore, mapConfigs);
      return geoJSON;
    }

    /**
    * Function that creates anchor annotations to display them on the map
    */
    handleLoadLocationAnnotationsOnMapBasedOnAnchor = (campusId, token, mapConfigs) =>{
        try{
            axios.get(`${secureUrl.hostUrl}/api/anchors/anchorsByCampus?campusId=${campusId}&access_token=${token}`)
            .then(resp=>{
                if (resp.data.response.length !== 0) {
                    const locations = resp.data.response[0].features;
                    this.map = handlePaintAnchorLocationsOnMap(locations, this.map, mapConfigs);
                }
            });
        }catch(err){
            console.error("[MAP] Could't get anchor info");
        }
    }

    handleAnchorAnnotationsCalloutsEnabled = (annotation, jobTasks, ordinal, jobInfo) =>{
        let firstTaskId = null;

        if (jobInfo.status === 'Error' &&  ( jobInfo.errorNumber === ERROR_TASKS.jobStartDelay.key || (!!jobInfo.job_errors && jobInfo.job_errors.includes(ERROR_TASKS.jobStartDelay.key)) ) ) {
            firstTaskId = jobTasks[0].taskid;
        }

        if (annotation.subtitle === 'anchorLocation') {
            const locationInJob = jobTasks.find(task=>task.location_name === annotation.data.name) || null;
            if (parseInt(annotation.data.ordinal) === parseInt(ordinal) && !!locationInJob) {
                return true;
            }
            else {
                return false;
            }
        }
        if (annotation.subtitle === 'anchorCallout') {
            const locationInJob = jobTasks.find(task=>task.location_name === annotation.data.name) || null;
            if (parseInt(annotation.data.ordinal) === parseInt(ordinal) && !!locationInJob && locationInJob.job_error_number === null  && locationInJob.taskid !== firstTaskId) {
                return true;
            }
            else {
                return false;
            }
        }
        if (annotation.subtitle === 'anchorCalloutError') {
            const locationInJob = jobTasks.find(task=>task.location_name === annotation.data.name) || null;
            if (parseInt(annotation.data.ordinal) === parseInt(ordinal) && !!locationInJob && (!!locationInJob.job_error_number  || locationInJob.taskid === firstTaskId)) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    handleAnchorAnnotationsCalloutsDisabled = (annotation, jobTasks, ordinal) =>{
        if (annotation.subtitle === 'anchorLocation') {
            const locationInJob = jobTasks.find(task=>task.location_name === annotation.data.name) || null;
            if (parseInt(annotation.data.ordinal) === parseInt(ordinal) && !!locationInJob) {
                return true;
            }
            else {
                return false;
            }
        }
        if (annotation.subtitle === 'anchorCallout') {
            return false;
        }
        if (annotation.subtitle === 'anchorCalloutError') {
            return false;
        }
    }

    /**
    * Function that based on the floor (floorId) and the jobTasks determines which anchor locations to show or hide
    */
    handleShowAnchorAnnotations = (ordinal, jobTasks, showCallouts, jobInfo) =>{
        this.map.annotations.map(annotation=>{
            if (showCallouts){
                annotation.visible = this.handleAnchorAnnotationsCalloutsEnabled(annotation, jobTasks, ordinal, jobInfo[0]);
            }
            else {
                annotation.visible = this.handleAnchorAnnotationsCalloutsDisabled(annotation, jobTasks, ordinal);
            }
            return null;
        });
    }

    /**
    * Function that hides all the anchor annotations
    */
    handleHideAllAnchorAnnotations = () =>{
        this.map.annotations.forEach(annotation=>{
            if (annotation.subtitle === 'anchorLocation' || annotation.subtitle === 'anchorCallout' || annotation.subtitle === 'anchorCalloutError') {
                annotation.visible = false;
            }
        });
    }

    /**
    * Function that shows the anchor annotations corresponding to the defaultFloor and based on the jobTasks
    */
    handleShowDefaultAnchorAnnotations = (defaultOrdinal, jobTasks, jobInfo) => {
        let firstTaskId = null;

        if (jobInfo[0].status === 'Error' &&  ( jobInfo[0].errorNumber === ERROR_TASKS.jobStartDelay.key || (!!jobInfo[0].job_errors && jobInfo[0].job_errors.includes(ERROR_TASKS.jobStartDelay.key)) ) ) {
            firstTaskId = jobTasks[0].taskid;
        }

        this.map.annotations.map(annotation=>{
            if (annotation.subtitle === 'anchorLocation') {
                const locationInJob = jobTasks.find(task=>task.location_name === annotation.data.name) || null;
                if (parseInt(annotation.data.ordinal) === parseInt(defaultOrdinal) && !!locationInJob) {
                    annotation.visible = true;
                }
                else {
                    annotation.visible = false;
                }
            }
            if (annotation.subtitle === 'anchorCallout') {
                const locationInJob = jobTasks.find(task=>task.location_name === annotation.data.name) || null;
                if (parseInt(annotation.data.ordinal) === parseInt(defaultOrdinal) && !!locationInJob && locationInJob.job_error_number === null  && locationInJob.taskid !== firstTaskId) {
                    annotation.visible = true;
                }
                else {
                    annotation.visible = false;
                }
            }
            if (annotation.subtitle === 'anchorCalloutError') {
                const locationInJob = jobTasks.find(task=>task.location_name === annotation.data.name) || null;
                if (parseInt(annotation.data.ordinal) === parseInt(defaultOrdinal) && !!locationInJob && (!!locationInJob.job_error_number || locationInJob.taskid === firstTaskId)) {
                    annotation.visible = true;
                }
                else {
                    annotation.visible = false;
                }
            }
            return null;
        });
    }

}