import {
  CHANGE_DASHBOARD_CONTENT,
  CHANGE_CALLOUTS,
  CHANGE_TRACES,
  CHANGE_ACTIVE_FLOOR,
  DRAWER_CHANGE,
  DEFAULT_VIEW_LOADED,
  CHANGE_ACTIVE_USER,
  CHANGE_TIME_WINDOW,
  CHANGE_HIDE_JOB,
  CHANGE_WIFI,
  CHANGE_GEOJSON_JOB,
  CHANGE_CENTER_HISTORY_MAP,
  OPEN_HISTORY_TABLE,
  CHANGE_SMALL_CALLOUTS,
  STORE_HISTORY_TRACES,
  STORE_USERS_STREAMING,
  CHANGE_PEOPLE_FILTER,
  CHANGE_EQUIPMENT_FILTER,
  SET_MENU_VALUE,
  ZOOM_ON_USER,
  USER_CHAT,
  USER_CHAT_UPDATED,
  UNREAD_MSG_LIST,
  OPEN_BROADCAST_CHAT,
  UNREAD_BROADCAST_CHAT,
  ZOOM_ON_EQUIPMENT,
  ANIMATION_SHOW_TRACES,
  ANIMATION_SHOW_CALLOUTS,
  ANIMATION_CURRENT_FLOOR_ONLY,
  CHANGE_INDOOR_ONLY,
  CENTER_MAP,
  REMOTE_SELECT_TAB,
  SET_LAST_VIEWPROPS,
  SET_MAP_CONFIGS
} from './types';
import axios from 'axios';
import { MAP_CONFIG_LOCAL, LOCALHOST, MAP_CONFIG } from "./views.constants";
export const changeView = (view, prop = '', data = false) => dispatch => {
  if (view === "table") {
    // if(prop==='history'){
    dispatch({
      type: CHANGE_DASHBOARD_CONTENT,
      payload: {
        currentView: view,
        viewProps: prop,
        historyRoute: false,
        jobTasks: false,
      }
    });
    return false;
    // }
  }
  else if (view === "contact" && prop === 'contact') {
    dispatch({
      type: CHANGE_DASHBOARD_CONTENT,
      payload: {
        currentView: view,
        viewProps: prop,
        historyRoute: false,
        jobTasks: false,
      }
    });
    return false;
  }
  else if (view === "direct_contact" && prop === "direct_contact") {
    dispatch({
      type: CHANGE_DASHBOARD_CONTENT,
      payload: {
        currentView: view,
        viewProps: prop,
        historyRoute: false,
        jobTasks: false,
      }
    });
    return false;
  }
  else if (view === "model_phone" && prop === "model_phone") {
    dispatch({
      type: CHANGE_DASHBOARD_CONTENT,
      payload: {
        currentView: view,
        viewProps: prop,
        historyRoute: false,
        jobTasks: false,
      }
    });
    return false;
  }
  else if (view === "details_contact" && prop === "details_contact") {
    dispatch({
      type: CHANGE_DASHBOARD_CONTENT,
      payload: {
        currentView: view,
        viewProps: prop,
        historyRoute: false,
        jobTasks: false,
      }
    });
    return false;
  }
  else if (view === 'map') {
    switch (prop) {
      case "history":
        dispatch({
          type: CHANGE_DASHBOARD_CONTENT,
          payload: {
            currentView: view,
            transporterFound: false,
            transporterPhone: false,
            historyRoute: data.traces,
            jobTasks: false,
            jobInfo: data.jobInfo,
            allCallouts: false,
            viewProps: prop,
            timeWindow: [],
            jobsToHide: [],
            wifi: false,
            geoJSONJob: {},
            centerHistoryMap: ''
          }
        });
        break;
      case 'previewEquipment':
        dispatch({
          type: CHANGE_DASHBOARD_CONTENT,
          payload: {
            currentView: view,
            transporterFound: false,
            transporterPhone: false,
            historyRoute: false,
            jobTasks: false,
            jobInfo: [],
            allCallouts: true,
            phoneFound: data,
            viewProps: prop,
            timeWindow: [],
            jobsToHide: [],
            wifi: false,
            geoJSONJob: {},
            centerHistoryMap: ''
          }
        });
        break;
      case "transporter":
        dispatch({
          type: CHANGE_DASHBOARD_CONTENT,
          payload: {
            currentView: view,
            transporterFound: data.userId,
            transporterPhone: data.deviceId,
            historyRoute: false,
            jobTasks: false,
            jobInfo: [],
            allCallouts: true,
            phoneFound: data,
            viewProps: prop,
            timeWindow: [],
            jobsToHide: [],
            wifi: false,
            geoJSONJob: {},
            centerHistoryMap: ''
          }
        });
        break;
      case 'transporterPhone':
        dispatch({
          type: CHANGE_DASHBOARD_CONTENT,
          payload: {
            currentView: view,
            transporterFound: data.userId,
            transporterPhone: data.deviceId,
            historyRoute: false,
            jobTasks: false,
            jobInfo: [],
            allCallouts: true,
            phoneFound: data,
            viewProps: prop,
            timeWindow: [],
            jobsToHide: [],
            wifi: false,
            geoJSONJob: {},
            centerHistoryMap: ''
          }
        });
        break;
      case "contact_location":
        dispatch({
          type: CHANGE_DASHBOARD_CONTENT,
          payload: {
            directContactsLocation: true,
            currentView: view,
            viewProps: prop,
            historyRoute: false,
            jobTasks: false,
          }
        });
        return false;
      case "workflowAutomation":
        dispatch({
          type: CHANGE_DASHBOARD_CONTENT,
          payload: {
            currentView: view,
            transporterFound: false,
            transporterPhone: false,
            historyRoute: false,
            jobTasks: data.tasks,
            jobInfo: data.jobInfo,
            allCallouts: false,
            viewProps: prop,
            timeWindow: [],
            jobsToHide: [],
            wifi: false,
            geoJSONJob: {},
            centerHistoryMap: ''
          }
        });
        break;
      default:
        dispatch({
          type: CHANGE_DASHBOARD_CONTENT,
          payload: {
            currentView: view,
            transporterFound: false,
            transporterPhone: false,
            jobInfo: [],
            historyRoute: false,
            jobTasks: false,
            allCallouts: true,
            timeWindow: [],
            jobsToHide: [],
            wifi: false,
            geoJSONJob: {},
            centerHistoryMap: '',
            viewProps: '',
          }
        });
    }
  }
  else {
    dispatch({
      type: CHANGE_DASHBOARD_CONTENT,
      payload: {
        viewProps: prop,
        currentView: view,
        transporterFound: false,
        transporterPhone: false,
        historyRoute: false,
        allCallouts: false,
        timeWindow: [],
        jobsToHide: [],
        jobTasks: false,
        jobInfo: [],
        wifi: false,
        geoJSONJob: {},
        centerHistoryMap: ''
      }
    });
  }
};

export const setCurrentMenuValue = (data) => dispatch => {
  dispatch({
    type: SET_MENU_VALUE,
    payload: data,
  });
};

export const changeAllCallouts = (checked) => dispatch => {
  dispatch({
    type: CHANGE_CALLOUTS,
    payload: checked,
  });
};

export const changeSmallCallouts = (checked) => dispatch => {
  dispatch({
    type: CHANGE_SMALL_CALLOUTS,
    payload: checked,
  });
};

export const changeAllTraces = (checked) => dispatch => {
  dispatch({
    type: CHANGE_TRACES,
    payload: checked,
  });
};

export const changeActiveFloor = (checked) => dispatch => {
  dispatch({
    type: CHANGE_ACTIVE_FLOOR,
    payload: checked,
  });
};

export const changePeopleFilter = (data) => dispatch => {
  dispatch({
    type: CHANGE_PEOPLE_FILTER,
    payload: data,
  });
};

export const changeEquipmentFilter = (data) => dispatch => {
  dispatch({
    type: CHANGE_EQUIPMENT_FILTER,
    payload: data,
  });
};

export const changeTimeWindow = (data) => dispatch => {
  dispatch({
    type: CHANGE_TIME_WINDOW,
    payload: data
  });
};

export const changeHideJobs = (data) => dispatch => {
  dispatch({
    type: CHANGE_HIDE_JOB,
    payload: data
  });
};

export const changeWifiFilter = (data) => dispatch => {
  dispatch({
    type: CHANGE_WIFI,
    payload: data
  });
};

export const changeGeoJSONJobs = (data) => dispatch => {
  dispatch({
    type: CHANGE_GEOJSON_JOB,
    payload: data
  });
};

export const storeHistoryTraces = (data) => dispatch => {
  dispatch({
    type: STORE_HISTORY_TRACES,
    payload: data
  });
};

export const changeCenterHistoryMap = (data) => dispatch => {
  dispatch({
    type: CHANGE_CENTER_HISTORY_MAP,
    payload: data
  });
};

export const changeActiveUser = (checked) => dispatch => {
  dispatch({
    type: CHANGE_ACTIVE_USER,
    payload: checked,
  });
};

export const changeDrawer = () => (dispatch, getState) => {
  dispatch({
    type: DRAWER_CHANGE,
    payload: !getState().views.drawerOpen,
  });
};

export const initialViewLoaded = () => (dispatch) => {
  dispatch({
    type: DEFAULT_VIEW_LOADED,
  });
};

export const changeHistoryTable = (data) => (dispatch) => {
  dispatch({
    type: OPEN_HISTORY_TABLE,
    payload: data
  });
};

export const changeZoomToEquipment = (data) => dispatch => {
  let showCallout = data !== '' ? true : false;
  dispatch({
    type: ZOOM_ON_EQUIPMENT,
    payload: {
      equipmentSelected: data,
      // allCallouts: showCallout
    }
  });
};

export const changeZoomToUser = (data) => dispatch => {
  dispatch({
    type: ZOOM_ON_USER,
    payload: {
      transporterFound: data.userId,
      transporterPhone: data.deviceId,
      allCallouts: true
    }
  });
};

export const userChatSelected = (user) => ((dispatch) => {
  dispatch({
    type: USER_CHAT,
    payload: {
      userChat: user
    }
  });
});

export const userChatUpdated = (user) => ((dispatch) => {
  dispatch({
    type: USER_CHAT_UPDATED,
    payload: {
      userChatUpdated: user
    }
  });
});

export const setUnreadMsgsList = (data) => ((dispatch) => {
  dispatch({
    type: UNREAD_MSG_LIST,
    payload: {
      unreadMsgsList: data
    }
  });
});

export const openBroadcastChat = (data) => dispatch => {
  dispatch({
    type: OPEN_BROADCAST_CHAT,
    payload: data
  });
};

export const unreadBroadcastChat = (data) => dispatch => {
  dispatch({
    type: UNREAD_BROADCAST_CHAT,
    payload: data
  });
};

export const showAnimationTrace = (checked) => dispatch => {
  dispatch({
    type: ANIMATION_SHOW_TRACES,
    payload: checked
  });
};

export const showAnimationCallouts = (checked) => dispatch => {
  dispatch({
    type: ANIMATION_SHOW_CALLOUTS,
    payload: checked
  });
};

export const showAnimationCurrentFloorOnly = (checked) => dispatch => {
  dispatch({
    type: ANIMATION_CURRENT_FLOOR_ONLY,
    payload: checked
  });
};

export const changeIndoorOnly = (checked) => dispatch => {
  dispatch({
    type: CHANGE_INDOOR_ONLY,
    payload: checked
  });
};

export const centerActiveMap = (data) => dispatch => {
  dispatch({
    type: CENTER_MAP,
    payload: data
  });
};

export const selectTabRemote = (data) => dispatch => {
  dispatch({
    type: REMOTE_SELECT_TAB,
    payload: data
  });
};

export const updateLastViewProps = (data) => dispatch => {
  dispatch({
    type: SET_LAST_VIEWPROPS,
    payload: data
  });
};

const handleConfigMap = (hostname) => {
  return hostname !== LOCALHOST ? MAP_CONFIG : MAP_CONFIG_LOCAL;
}

export const setMapConfigurations = () => dispatch => {
  return new Promise ((resolve, reject)=>{
      const url = handleConfigMap(window.location.hostname)
      axios.get(url)
      .then(resp => {
        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (!!userInfo.campusInfo.location_callouts) {
          resp.data.floors = [...userInfo.campusInfo.location_callouts];
          console.log("location_callouts data added in mapConfig object")
        }
        else {
          resp.data.floors = [];
          console.error("campus collection does not contain location_callouts data");
        }
        dispatch({
          type: SET_MAP_CONFIGS,
          payload: resp.data,
        });
        resolve(resp);
      }).catch((error) => {
        console.error("Map configuration data request failed: ", JSON.stringify(error));
        resolve(false);
      });
});
};
