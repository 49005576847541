import * as CONSTS from './IMDF.constants';
const {LOAD_IMDF}= require('./mapLoader');

let historyTraceStore = [];
let ordinalFloor = -3;
let currentFloorActive= false;
let showHistoryMap= true;
let campusRotation = 0;

export default class indoorMap{
      constructor(){
        const span = new window.mapkit.CoordinateSpan(CONSTS.SPAN_DEFAULT.lat, CONSTS.SPAN_DEFAULT.lon)
        const center=new window.mapkit.Coordinate(CONSTS.CENTER_DEFAULT.lat, CONSTS.CENTER_DEFAULT.lon);
        const region = new window.mapkit.CoordinateRegion(center, span);
                this.attributes={
                    region:region,
                    rotation:campusRotation,
                    showsPointsOfInterest: false,
                    showsMapTypeControl: false,
                    showsCompass: CONSTS.HIDDEN,
                    showsZoomControl: false
                };
        this.map = new window.mapkit.Map(CONSTS.PREVIEW_MAP,this.attributes);
    };

    handleMapDefault = ()=>{
        this.map.center = new window.mapkit.Coordinate(CONSTS.CENTER_DEFAULT.lat, CONSTS.CENTER_DEFAULT.lon);
        this.map.span= new window.mapkit.CoordinateSpan(CONSTS.SPAN_DEFAULT.lat, CONSTS.SPAN_DEFAULT.lon);
        this.map.region= new window.mapkit.CoordinateRegion(this.map.center, this.map.span);
        let map = this.map;
        map.rotation = 0;
        this.map = map;
    };

    handleConfigMap = async (config)=>{
        this.defaultFloor=config.floor;
        this.token=config.token;
        this.campusId=config.campusId;
        if(config.filesAvailable)
          this.loadImdfArchive(this);
    }

    updateSpan= (zoomType,rotation)=>{
        this.map.span= new window.mapkit.CoordinateSpan(CONSTS.ZOOM_INFO[zoomType].lat, CONSTS.ZOOM_INFO[zoomType].lon);
        this.map.region= new window.mapkit.CoordinateRegion(this.map.center, this.map.span);
        this.updateRotation(rotation);
    }

    updateCoordinates = (lat, lon, rotation)=>{
        this.map.center = new window.mapkit.Coordinate(lat,lon);
        this.map.region= new window.mapkit.CoordinateRegion(this.map.center, this.map.span);
        this.updateRotation(rotation);
    }

    updateRotation = (value)=>{
        let map = this.map;
        map.rotation = value;
        this.map = map;
    }

   loadImdfArchive=({map,attributes,defaultFloor,token,campusId})=>{
    [ordinalFloor, currentFloorActive, historyTraceStore, showHistoryMap, this.map] = LOAD_IMDF({map,attributes, defaultFloor, token, campusId, ordinalFloor, currentFloorActive, historyTraceStore, showHistoryMap});
    }

}
