import moment from "moment";
import { getFloorColorByOrdinal } from "../../floorColors";

export const createHistoryTraceAnnotation = (mapkit, TagCoords, TagOptions, mapConfigs)=>{
    let TagFactory = handleFactoryAnnotationHistoryTrace(TagCoords, TagOptions, mapConfigs);
    var tag = new window.mapkit.Annotation(TagCoords, TagFactory, TagOptions);
    mapkit.addAnnotation(tag);
    return mapkit;
};
  
export const createHistoryTraceAnnotationCallout = (mapkit, TagCoords, TagOptions, mapConfigs)=>{
    let TagFactory = handleFactoryAnimationAnnotationCallout(TagCoords, TagOptions, mapConfigs);
    var tag = new window.mapkit.Annotation(TagCoords, TagFactory, TagOptions);
    mapkit.addAnnotation(tag);
    return mapkit;
};
  
const handleFactoryAnnotationHistoryTrace = (coordinate, options, mapConfigs)=>{
    return (coordinate, options) =>{
      let divContainer = document.createElement("div");
      let divPin = document.createElement("div");
      if (options.data.title === "endTag") {
        let endHistoryPinStyles = Object.keys(mapConfigs).length ? mapConfigs.history.location_Callout.end_HistoryPin : {};
        let endHistoryPinProperties = Object.keys(endHistoryPinStyles);
        endHistoryPinProperties.forEach(property=>{
          divPin.style[property] = endHistoryPinStyles[property];
        });
      }else {
        let startHistoryPinStyles = Object.keys(mapConfigs).length ? mapConfigs.history.location_Callout.start_HistoryPin : {};
        let startHistoryPinProperties = Object.keys(startHistoryPinStyles);
        startHistoryPinProperties.forEach(property=>{
          divPin.style[property] = startHistoryPinStyles[property];
        });
      }
      divContainer.append(divPin);
      return divContainer;
    };
};
  
const handleFactoryAnimationAnnotationCallout = (coordinate, options, mapConfigs)=>{
    return (coordinate, options) =>{
      let actualFloor = '';
      let floorColor = "";
      let nameLblSize = "10px";
      const currentFloor = !!mapConfigs.floors ? mapConfigs.floors.filter(floor => floor.ordinal === options.data.floor) : null;
      if (!!currentFloor && currentFloor.length) {
        actualFloor = currentFloor[0].label;
        //floorColor = currentFloor[0].color;
        floorColor = getFloorColorByOrdinal(currentFloor[0].ordinal);
      }
  
      let divCallout = document.createElement("div"), id = "marker";
      divCallout.className = "history-callout";
      let historyCalloutStyles = Object.keys(mapConfigs).length ? mapConfigs.history.location_Callout.main_Container : {};
      let historyCalloutProperties = Object.keys(historyCalloutStyles);
      historyCalloutProperties.forEach(property=>{
        divCallout.style[property] = historyCalloutStyles[property];
      });
  
      let divTitle = document.createElement("div");
      let titleContainerStyles = Object.keys(mapConfigs).length ? mapConfigs.history.location_Callout.title_Container : {};
      let titleContainerProperties = Object.keys(titleContainerStyles);
      titleContainerProperties.forEach(property=>{
        divTitle.style[property] = titleContainerStyles[property];
      });
      divTitle.append(options.title);
  
      let divAsset = document.createElement("div");
      let tagContainerStyles = Object.keys(mapConfigs).length ? mapConfigs.history.location_Callout.tag_Container : {};
      let tagContainerProperties = Object.keys(tagContainerStyles);
      tagContainerProperties.forEach(property=>{
        divAsset.style[property] = tagContainerStyles[property];
      });
      divAsset.append(options.data.label);
  
      let divTag = document.createElement("div");
      divTag.style.display = 'flex';
      divTag.append(divAsset);
  
      let divDate = document.createElement("div");
      let dateContainerStyles = Object.keys(mapConfigs).length ? mapConfigs.history.location_Callout.date_Container : {};
      let dateContainerProperties = Object.keys(dateContainerStyles);
      dateContainerProperties.forEach(property=>{
        divDate.style[property] = dateContainerStyles[property];
      });
      divDate.append(options.data.date);
  
      let divData = document.createElement("div");
      divData.style.textAlign = 'left';
      divData.append(divTitle);
      divData.append(divTag);
      divData.append(divDate);
  
      let divPointer = document.createElement("div");
      let pointerStyles = Object.keys(mapConfigs).length ? mapConfigs.history.location_Callout.pointer : {};
      let pointerProperties = Object.keys(pointerStyles);
      pointerProperties.forEach(property=>{
        divPointer.style[property] = pointerStyles[property];
      });
      divPointer.style.fontSize = nameLblSize;
      divPointer.append(divData);
      divCallout.append(divPointer);
  
      let divLevel = document.createElement("div");
      let floorDivStyles = Object.keys(mapConfigs).length ? mapConfigs.history.location_Callout.floor_Container : {};
      let floorDivProperties = Object.keys(floorDivStyles);
      floorDivProperties.forEach(property=>{
        divLevel.style[property] = floorDivStyles[property];
      });
      divLevel.style.backgroundColor = floorColor;
      let label = actualFloor !== "GPS" ? "L-" + actualFloor : actualFloor;
      divLevel.append(label);
      divCallout.append(divLevel);
  
      return divCallout;
    };
};


export const groupLocationsByJob = (tracesAux) =>{
  let grouped = Object.create(null);

  tracesAux.forEach (function(a) {
    grouped[a.jobId] = grouped[a.jobId] || [];
    grouped[a.jobId].push(a);
  });

  return grouped;
};

export const handleTraceAnnotationCoords = (job, lastItem) =>{
  return [
    [
      {
        latitude: job[0].geolocation.latitude,
        longitude: job[0].geolocation.longitude,
        floor: job[0].geolocation.floor,
        date: moment(job[0].create_date).format("MM/DD/YYYY HH:mm:ss")
      },
      {
        latitude: job[lastItem].geolocation.latitude,
        longitude: job[lastItem].geolocation.longitude,
        floor: job[lastItem].geolocation.floor,
        date: moment(job[lastItem].create_date).format("MM/DD/YYYY HH:mm:ss")
      }
    ]
  ];
};

export const getCurrentFloorColor = (mapConfigs, trace) =>{
  let floorColor = "";
  
  const currentFloor = !!mapConfigs.floors ? mapConfigs.floors.filter(floor => floor.ordinal === trace[0][0]) : null;

  if(!!currentFloor && currentFloor.length) {
    floorColor = getFloorColorByOrdinal(currentFloor[0].ordinal);
    //floorColor =  currentFloor[0].color;
  }

  return floorColor;
}

export const polyline_showWifi_filter = (lastDot) =>{
  let newfloorColor = lastDot.traceHealing ? "#ff80ab" : "#0083C5";
  let traceStatus = lastDot.traceHealing ? "healed" : "normal";
    if (!lastDot.socketIO) {
        newfloorColor = "#ff9100";
        traceStatus = "without-socketIO";
    }

  return {newfloorColor, traceStatus};
}

export const polylineJobTrace = (polylineJob, ordinalF, traceStatus, floorColor, coordinatesPerJob) =>{
  polylineJob.push({
    "type": "Feature",
    "properties": {
      "floor": ordinalF === null ? 'outside' : ordinalF,
      "trace-status": traceStatus,
      "stroke": floorColor,
      "stroke-width": 2,
      "stroke-opacity": 1
    },
    "geometry": {
      "type": "LineString",
      "coordinates": coordinatesPerJob
    }});

  return polylineJob;
}

export const setPolylineStyle = (lineStyle, floorColor) =>{
  return new window.mapkit.Style({
    lineWidth: 2,
    lineJoin: "round",
    lineDash: lineStyle,
    strokeColor: floorColor,
  });
}

export const setPolylineCoords = (trace) =>{
  let coordinatesPerJob = [];
  let mapkitCoords = null;

  mapkitCoords = trace[0][1].map((point)=> {
    coordinatesPerJob.push([point.coordinates[1], point.coordinates[0]]);
    return new window.mapkit.Coordinate(point.coordinates[0], point.coordinates[1]);
  });

  return {coordinatesPerJob, mapkitCoords}
}


export const checkLocationDotsInJob = (job, points, showHistoryTrace) =>{
  let traceHealingFlag = false;
  let socketIOEnabledFlag = true;
  let currentMissingTrace = false;
  let missingTraceFlag = false;
  let currentChunk = '';
  let currentFloor = 0;
  let currentList = [];
  let coordinates = [];
  let floorList = [];
  let lastCoordinates = [];

  job.forEach((dot, index)=>{
    if (dot.chunkId !== null) {
      if (dot.geolocation.latitude !== 0 && dot.geolocation.longitude !== 0) {
        if (dot.isNetworkAvailable && dot.isSocketIOEnabled) {
          traceHealingFlag = false;
        }
        else if (!dot.isNetworkAvailable) {
          traceHealingFlag = true;
        }
        else if (dot.isNetworkAvailable && !dot.isSocketIOEnabled) {
          socketIOEnabledFlag = false;
        }

        if (index !== 0) {
        //? Chunk change
          if (currentChunk !== dot.chunkId) {
            missingTraceFlag = true;
          }
          else {
            missingTraceFlag = false;
          }
        }
        if (index === 0) {
          coordinates = [dot.geolocation.latitude, dot.geolocation.longitude];
          currentList.push({coordinates: coordinates, traceHealing: traceHealingFlag, missingTrace: missingTraceFlag, socketIO: socketIOEnabledFlag});
        } else if (currentFloor === dot.geolocation.floor && currentMissingTrace === missingTraceFlag) {
          coordinates = [dot.geolocation.latitude, dot.geolocation.longitude];
          currentList.push({coordinates: coordinates, traceHealing: traceHealingFlag, missingTrace: missingTraceFlag, socketIO: socketIOEnabledFlag});
        } else {
          floorList.push([currentFloor, currentList]);
          points.push(floorList);
          currentList = [];
          floorList = [];

          //?Include the coordinates of the lastlocation (before it changed)
          coordinates = lastCoordinates[0];
          currentList.push({coordinates: coordinates, traceHealing: lastCoordinates[1], missingTrace: lastCoordinates[2], socketIO: lastCoordinates[3]});

          //?Include current coordinates
          coordinates = [dot.geolocation.latitude, dot.geolocation.longitude];
          currentList.push({coordinates: coordinates, traceHealing: traceHealingFlag, missingTrace: missingTraceFlag, socketIO: socketIOEnabledFlag});
        }
        currentFloor = dot.geolocation.floor;
        if ((job.length - 1) === index) {
          floorList.push([currentFloor, currentList]);
          points.push(floorList);
        }
        currentChunk = dot.chunkId;
        lastCoordinates = [coordinates, traceHealingFlag, missingTraceFlag, socketIOEnabledFlag];
        currentMissingTrace = missingTraceFlag;
        missingTraceFlag = false;
        socketIOEnabledFlag = true;

      } else {
        console.warn("[HANDLETRACES] Wrong coords detected");
      }
    } else {
      showHistoryTrace = false;
    }
  });

  return points;
}